import { createBrowserRouter } from 'react-router-dom';
import { App } from './layouts/App';
import { Dashboard } from './layouts/Dashboard';
import { Guest } from './layouts/Guest';
import { Analytics } from './pages/Analytics';
import { Appearance } from './pages/Appearance';
import { ChangeEmail } from './pages/ChangeEmail';
import { ChangeUsername } from './pages/ChangeUsername';
import { Error } from './pages/Error';
import { Home } from './pages/Home';
import { Links } from './pages/Links';
import { Paiz } from './pages/Paiz';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { Settings } from './pages/Settings';
import { Signin } from './pages/Signin';
import { Signup } from './pages/Signup';
import { SocialIcons } from './pages/SocialIcons';
import { TermsAndConditions } from './pages/TermsAndConditions';
import { UpgradeToPro } from './pages/UpgradeToPro';
import { Verified } from './pages/Verified';

export const Router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: '/',
                element: <Home />
            },
            {
                path: '/verified',
                element: <Verified />
            },
            {
                path: '/privacy-policy',
                element: <PrivacyPolicy />
            },
            {
                path: '/terms-and-conditions',
                element: <TermsAndConditions />
            }
        ]
    },
    {
        path: '/',
        element: <Guest />,
        children: [
            {
                path: '/signin',
                element: <Signin />
            },
            {
                path: '/signup',
                element: <Signup />
            }
        ]
    },
    {
        path: '/',
        element: <Dashboard />,
        children: [
            {
                path: '/analytics',
                element: <Analytics />
            },
            {
                path: '/appearance',
                element: <Appearance />
            },
            {
                path: '/edit/email',
                element: <ChangeEmail />
            },
            {
                path: '/edit/username',
                element: <ChangeUsername />
            },
            {
                path: '/links',
                element: <Links />
            },
            {
                path: '/settings',
                element: <Settings />
            },
            {
                path: '/social-icons',
                element: <SocialIcons />
            },
            {
                path: '/upgrade-to-pro',
                element: <UpgradeToPro />
            }
        ]
    },
    {
        path: '/:username',
        element: <Paiz />,
        errorElement: <Error />
    }
]);