import { AppBar, Avatar, Button, Container, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, SvgIcon, Toolbar } from '@mui/material';
import { Fragment, useState } from 'react';
import { LogOut, Menu as MenuIcon, Moon, Sun, User } from 'react-feather';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { setAccessToken, setUser } from '../../redux/auth/actions';
import { RootState } from '../../redux/configureStore';
import { setMode } from '../../redux/theme/actions';
import { Spacer } from '../common/Spacer';

type DashboardHeaderProps = {
    setAccessToken: (accessToken: string | null) => void;
    setUser: (user: any) => void;
    setMode: (mode: 'dark' | 'light') => void;
    user: any;
    mode: 'dark' | 'light';
};

const mapStateToProps = (state: RootState) => ({
    user: state.auth.user,
    mode: state.theme.mode
});

const mapDispatchToProps = {
    setAccessToken,
    setUser,
    setMode
};

export const DashboardHeader = connect(mapStateToProps, mapDispatchToProps)((props: DashboardHeaderProps): JSX.Element => {
    const { pathname } = useLocation();
    const {
        setAccessToken,
        setUser,
        setMode,
        user,
        mode
    } = props;
    const [
        anchorEl,
        setAnchorEl
    ] = useState<null | HTMLElement>(null);
    const [
        open,
        setOpen
    ] = useState(false);

    return (
        <Fragment>
            <AppBar
                position="static"
                color="transparent"
                elevation={0}
                enableColorOnDark
                sx={{
                    marginTop: 2
                }}
            >
                <Container maxWidth="xl">
                    <Toolbar
                        disableGutters
                        sx={{
                            borderRadius: 4,
                            border: theme => `1px solid ${theme.palette.divider}`,
                            backgroundColor: 'background.paper',
                            padding: {
                                xs: '0 2px 0 3px',
                                sm: '0 6px 0 7px'
                            }
                        }}
                    >
                        <Avatar
                            component={Link}
                            to="/"
                            src="/static/images/logo-symbol.png"
                            alt="paiz logo symbol"
                            sx={{
                                padding: 1,
                                marginRight: 3,
                                width: 48,
                                height: 48,
                                backgroundColor: 'background.default'
                            }}
                        />
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={2}
                            sx={{
                                display: {
                                    xs: 'none',
                                    md: 'flex'
                                }
                            }}
                        >
                            <Button
                                component={Link}
                                to="/links"
                                variant="outlined"
                                color="inherit"
                                sx={{
                                    borderColor: pathname === '/links' ? 'currentColor' : 'transparent',
                                    color: pathname === '/links' ? 'text.primary' : 'text.secondary'
                                }}
                            >
                                Links
                            </Button>
                            <Button
                                component={Link}
                                to="/social-icons"
                                variant="outlined"
                                color="inherit"
                                sx={{
                                    borderColor: pathname === '/social-icons' ? 'currentColor' : 'transparent',
                                    color: pathname === '/social-icons' ? 'text.primary' : 'text.secondary'
                                }}
                            >
                                Social links
                            </Button>
                            <Button
                                component={Link}
                                to="/appearance"
                                variant="outlined"
                                color="inherit"
                                sx={{
                                    borderColor: pathname === '/appearance' ? 'currentColor' : 'transparent',
                                    color: pathname === '/appearance' ? 'text.primary' : 'text.secondary'
                                }}
                            >
                                Appearance
                            </Button>
                            <Button
                                component={Link}
                                to="/analytics"
                                variant="outlined"
                                color="inherit"
                                sx={{
                                    borderColor: pathname === '/analytics' ? 'currentColor' : 'transparent',
                                    color: pathname === '/analytics' ? 'text.primary' : 'text.secondary'
                                }}
                            >
                                Analytics
                            </Button>
                            <Button
                                component={Link}
                                to="/settings"
                                variant="outlined"
                                color="inherit"
                                sx={{
                                    borderColor: pathname === '/settings' ? 'currentColor' : 'transparent',
                                    color: pathname === '/settings' ? 'text.primary' : 'text.secondary'
                                }}
                            >
                                Settings
                            </Button>
                        </Stack>
                        <Spacer />
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={1}
                            sx={{
                                color: 'text.secondary'
                            }}
                        >
                            <IconButton
                                color="inherit"
                                edge="start"
                                onClick={() => {
                                    setOpen(true);
                                }}
                                sx={{
                                    display: {
                                        md: 'none'
                                    }
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <IconButton
                                color="inherit"
                                onClick={() => {
                                    setMode(mode === 'dark' ? 'light' : 'dark');
                                }}
                            >
                                {mode === 'dark'
                                    ? <Sun />
                                    : <Moon />
                                }
                            </IconButton>
                            <IconButton
                                size="small"
                                onClick={e => {
                                    setAnchorEl(e.currentTarget);
                                }}
                            >
                                {user.profileImage
                                    ?
                                    <Avatar
                                        src={`${process.env.REACT_APP_CDN_URL}/fit-in/160x160/${user.profileImage}`}
                                        sx={{
                                            width: 40,
                                            height: 40
                                        }}
                                    />
                                    :
                                    <Avatar
                                        sx={{
                                            width: 40,
                                            height: 40,
                                            backgroundColor: 'background.default',
                                            color: 'text.disabled'
                                        }}
                                    >
                                        <User />
                                    </Avatar>
                                }
                            </IconButton>
                        </Stack>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => {
                                setAnchorEl(null);
                            }}
                            PaperProps={{
                                sx: {
                                    minWidth: 240
                                }
                            }}
                            anchorOrigin={{
                                horizontal: 'right',
                                vertical: 'bottom'
                            }}
                            transformOrigin={{
                                horizontal: 'right',
                                vertical: 'top'
                            }}
                        >
                            <MenuItem
                                onClick={() => {
                                    setAccessToken(null);
                                    setUser(null);
                                }}
                            >
                                <ListItemIcon>
                                    <SvgIcon>
                                        <LogOut />
                                    </SvgIcon>
                                </ListItemIcon>
                                <ListItemText primary="Sign out" />
                            </MenuItem>
                        </Menu>
                    </Toolbar>
                </Container>
            </AppBar>
            <Drawer
                variant="temporary"
                anchor="right"
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                ModalProps={{
                    keepMounted: true
                }}
                PaperProps={{
                    sx: {
                        width: 280,
                        boxSizing: 'border-box',
                        backgroundImage: 'none'
                    }
                }}
            >
                <List>
                    <ListItem disablePadding>
                        <ListItemButton
                            component={Link}
                            to="/links"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <ListItemText
                                primary="Links"
                                primaryTypographyProps={{
                                    variant: pathname === '/links' ? 'subtitle1' : 'body1',
                                    color: pathname === '/links' ? 'text.primary' : 'text.secondary'
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            component={Link}
                            to="/social-icons"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <ListItemText
                                primary="Social links"
                                primaryTypographyProps={{
                                    variant: pathname === '/social-icons' ? 'subtitle1' : 'body1',
                                    color: pathname === '/social-icons' ? 'text.primary' : 'text.secondary'
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            component={Link}
                            to="/appearance"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <ListItemText
                                primary="Appearance"
                                primaryTypographyProps={{
                                    variant: pathname === '/appearance' ? 'subtitle1' : 'body1',
                                    color: pathname === '/appearance' ? 'text.primary' : 'text.secondary'
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            component={Link}
                            to="/analytics"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <ListItemText
                                primary="Analytics"
                                primaryTypographyProps={{
                                    variant: pathname === '/analytics' ? 'subtitle1' : 'body1',
                                    color: pathname === '/analytics' ? 'text.primary' : 'text.secondary'
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            component={Link}
                            to="/settings"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <ListItemText
                                primary="Settings"
                                primaryTypographyProps={{
                                    variant: pathname === '/settings' ? 'subtitle1' : 'body1',
                                    color: pathname === '/settings' ? 'text.primary' : 'text.secondary'
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
        </Fragment >
    );
});