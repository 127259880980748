import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../redux/configureStore';

export function withGuest<T>(WrappedComponent: React.ComponentType<T>) {
    const mapStateToProps = (state: RootState) => ({
        user: state.auth.user
    });

    return connect(mapStateToProps)((props: any): JSX.Element => {
        if (props.user) {
            return (
                <Navigate
                    to="/links"
                    replace
                />
            );
        } else {
            return (
                <WrappedComponent {...props} />
            );
        }
    });
}
