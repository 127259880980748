import { Button, Container, CssBaseline, Stack, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link, useRouteError } from 'react-router-dom';
import { RootState } from '../redux/configureStore';
import { dark } from '../themes/dark';
import { light } from '../themes/light';

type DashboardProps = {
    mode: 'dark' | 'light';
};

const mapStateToProps = (state: RootState) => ({
    mode: state.theme.mode
});

type ErrorResponse = {
    status: number;
    statusText: string;
};

export const Error = connect(mapStateToProps)((props: DashboardProps): JSX.Element => {
    const error = useRouteError() as ErrorResponse;

    return (
        <ThemeProvider theme={props.mode === 'dark' ? dark : light}>
            <Helmet>
                <meta name="theme-color" content={props.mode === 'dark' ? '#0e1015' : '#ffffff'} />
                <title>
                    {error.statusText} - Paiz.io
                </title>
            </Helmet>
            <CssBaseline />
            <Container
                maxWidth="xs"
                sx={{
                    padding: '160px 0 32px'
                }}
            >
                <Typography
                    variant="h1"
                    textAlign="center"
                >
                    {error.status}
                </Typography>
                {error.status === 404 && (
                    <Fragment>
                        <Typography
                            variant="body1"
                            color="text.secondary"
                            textAlign="center"
                            sx={{
                                marginTop: 2
                            }}
                        >
                            The page you're looking for doesn't exist.
                        </Typography>
                        <Typography
                            variant="body1"
                            color="text.secondary"
                            textAlign="center"
                        >
                            If you want this to be your username, please click the button below.
                        </Typography>
                        <Stack
                            alignItems="center"
                            sx={{
                                marginTop: 3
                            }}
                        >
                            <Button
                                component={Link}
                                to="/signup"
                                variant="outlined"
                            >
                                Sign up for free
                            </Button>
                        </Stack>
                    </Fragment>
                )}
                {error.status === 500 && (
                    <Fragment>
                        <Typography
                            variant="body1"
                            color="text.secondary"
                            textAlign="center"
                            sx={{
                                marginTop: 2
                            }}
                        >
                            We're working on fixing the problem. Please try again.
                        </Typography>
                        <Stack
                            alignItems="center"
                            sx={{
                                marginTop: 3
                            }}
                        >
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    window.location.reload();
                                }}
                            >
                                Refresh page
                            </Button>
                        </Stack>
                    </Fragment>
                )}
            </Container>
        </ThemeProvider>
    );
});