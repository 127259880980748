import { ApolloError, gql, MutationFunction, useQuery } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { Box, Container, Divider, Skeleton, TextField, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { createUseState } from '../components/common/UseState';
import { getValidationErrorMessage, parseErrors } from '../lib/helpers';
import { ValidationError } from '../types';

const UseValidationErrorsState = createUseState<ValidationError[]>();

const providers = [
    {
        label: 'Email address',
        placeholder: undefined,
        name: 'email'
    },
    {
        label: 'Phone',
        placeholder: undefined,
        name: 'phone'
    },
    {
        label: 'Discord URL',
        placeholder: 'https://discord.com/invite/yourchannel',
        name: 'discord'
    },
    {
        label: 'Facebook URL',
        placeholder: 'https://facebook.com/username',
        name: 'facebook'
    },
    {
        label: 'Instagram',
        placeholder: 'username',
        name: 'instagram'
    },
    {
        label: 'Linkedin URL',
        placeholder: 'https://linkedin.com/in/username',
        name: 'linkedin'
    },
    {
        label: 'Snapchat',
        placeholder: 'https://snapchat.com/add/username',
        name: 'snapchat'
    },
    {
        label: 'Soundcloud URL',
        placeholder: 'https://soundcloud.com/username',
        name: 'soundcloud'
    },
    {
        label: 'Tiktok',
        placeholder: '@username',
        name: 'tiktok'
    },
    {
        label: 'Twitch URL',
        placeholder: 'https://twitch.tv/username',
        name: 'twitch'
    },
    {
        label: 'Twitter',
        placeholder: '@username',
        name: 'twitter'
    },
    {
        label: 'Youtube URL',
        placeholder: 'https://www.youtube.com/channel/yourchannel',
        name: 'youtube'
    }
];

const CURRENT_USER_SOCIAL_ICONS = gql`
    query CurrentUserSocialIcons {
        currentUserSocialIcons {
            edges {
                provider
                url
            }
        }
    }
`;

const CREATE_OR_UPDATE_SOCIAL_ICON = gql`
    mutation CreateOrUpdateSocialIcon(
        $provider: String
        $url: String
    ) {
        createOrUpdateSocialIcon(input: {
            provider: $provider
            url: $url
        }) {
            id
        }
    }
`;

type SocialIcon = {
    provider: string;
    url: string;
};

export const SocialIcons = (): JSX.Element => {
    const { data, loading } = useQuery(CURRENT_USER_SOCIAL_ICONS, {
        fetchPolicy: 'no-cache'
    });

    return (
        <Fragment>
            <Helmet>
                <title>
                    Social links - Paiz.io
                </title>
            </Helmet>
            <Container maxWidth="sm">
                {loading && (
                    <Box
                        margin={{
                            xs: '32px 0 16px',
                            sm: '32px 0 24px'
                        }}
                    >
                        <Typography
                            variant="h4"
                            textAlign="center"
                            fontSize={{
                                xs: 24,
                                md: 34
                            }}
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            Social links
                        </Typography>
                        {providers.map((_, index) => (
                            <Fragment key={index}>
                                <Box
                                    sx={{
                                        paddingTop: 2,
                                        paddingBottom: 1
                                    }}
                                >
                                    <Skeleton
                                        variant="rounded"
                                        width="100%"
                                        height={56}
                                    />
                                </Box>
                                {index === 1 && (
                                    <Divider
                                        sx={{
                                            marginTop: 3,
                                            marginBottom: 2
                                        }}
                                    />
                                )}
                            </Fragment>
                        ))}
                    </Box>
                )}
                {data && (
                    <Box
                        margin={{
                            xs: '32px 0 16px',
                            sm: '32px 0 24px'
                        }}
                    >
                        <Typography
                            variant="h4"
                            textAlign="center"
                            fontSize={{
                                xs: 24,
                                md: 34
                            }}
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            Social links
                        </Typography>
                        {providers.map(({ label, placeholder, name }, index) => (
                            <Fragment key={index}>
                                <UseValidationErrorsState defaultValue={[]}>
                                    {(errors, setErrors) => (
                                        <Mutation
                                            mutation={CREATE_OR_UPDATE_SOCIAL_ICON}
                                            variables={{
                                                provider: name
                                            }}
                                            onError={(err: ApolloError) => {
                                                if (err.message === 'Bad Request Exception') {
                                                    setErrors(parseErrors(err));
                                                }
                                            }}
                                        >
                                            {(mutate: MutationFunction) => (
                                                <TextField
                                                    label={label}
                                                    placeholder={placeholder}
                                                    margin="normal"
                                                    variant="outlined"
                                                    defaultValue={data.currentUserSocialIcons.edges.find(({ provider }: SocialIcon) => provider === name)?.url}
                                                    error={errors.some(({ property }) => property === 'url')}
                                                    helperText={getValidationErrorMessage(errors, 'url')}
                                                    onChange={debounce(e => {
                                                        const variables = {
                                                            url: e.target.value
                                                        };
                                                        setErrors([]);
                                                        mutate({
                                                            variables
                                                        });
                                                    }, 500)}
                                                    fullWidth
                                                />
                                            )}
                                        </Mutation>
                                    )}
                                </UseValidationErrorsState>
                                {index === 1 && (
                                    <Divider
                                        sx={{
                                            marginTop: 3,
                                            marginBottom: 2
                                        }}
                                    />
                                )}
                            </Fragment>
                        ))}
                    </Box>
                )}
            </Container>
        </Fragment>
    );
};