import { Backdrop, CircularProgress, CssBaseline, ThemeProvider } from '@mui/material';
import { light } from '../account-themes/light';

export const Fetching = (): JSX.Element => {
    return (
        <ThemeProvider theme={light}>
            <CssBaseline />
            <Backdrop
                invisible
                open
            >
                <CircularProgress />
            </Backdrop>
        </ThemeProvider>
    );
};