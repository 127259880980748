import { ApolloProvider } from '@apollo/client';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { apolloClient } from './plugins/apollo-client';
import { persistor, store } from './redux/configureStore';
import { Router } from './Router';

export default function App() {
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <ApolloProvider client={apolloClient}>
                    <SnackbarProvider
                        maxSnack={3}
                        variant="success"
                        hideIconVariant
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                    >
                        <RouterProvider router={Router} />
                    </SnackbarProvider>
                </ApolloProvider>
            </PersistGate>
        </Provider>
    );
};
