import { Button, Container, Paper, Stack, SvgIcon, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { EyeOff } from 'tabler-icons-react';

type SensitiveContentWarningProps = {
    type: 'none' | 'sensitive' | 'eighteen-plus' | 'twenty-one-plus' | 'twenty-five-plus';
    onContinue: () => void;
};

export const SensitiveContentWarning = (props: SensitiveContentWarningProps): JSX.Element | null => {
    const {
        type,
        onContinue
    } = props;

    if (type === 'none') {
        return null;
    }

    return (
        <Paper
            elevation={0}
            square
            sx={{
                position: 'fixed',
                inset: 0,
                padding: '160px 0 32px',
                backgroundColor: 'background.default'
            }}
        >
            <Container maxWidth="xs">
                <Stack
                    alignItems="center"
                    sx={{
                        color: 'text.secondary'
                    }}
                >
                    <SvgIcon
                        color="inherit"
                        fontSize="large"
                    >
                        <EyeOff strokeWidth={1} />
                    </SvgIcon>
                </Stack>
                <Typography
                    variant="subtitle1"
                    textAlign="center"
                    textTransform="uppercase"
                    sx={{
                        marginTop: 2
                    }}
                >
                    Sensitive content
                </Typography>
                <Typography
                    variant="body1"
                    color="text.secondary"
                    textAlign="center"
                    sx={{
                        marginTop: 1
                    }}
                >
                    This profile may contain content that is not appropriate for all audiences
                </Typography>
                <Stack
                    justifyContent="center"
                    direction="row"
                    spacing={1}
                    sx={{
                        marginTop: 4
                    }}
                >
                    <Button
                        variant="outlined"
                        color="inherit"
                        onClick={() => {
                            onContinue();
                        }}
                    >
                        {(() => {
                            switch (type) {
                                case 'eighteen-plus': {
                                    return "I'm over 18";
                                }
                                case 'twenty-one-plus': {
                                    return "I'm over 21";
                                }
                                case 'twenty-five-plus': {
                                    return "I'm over 25";
                                }
                                default: {
                                    return "Continue";
                                }
                            }
                        })()}
                    </Button>
                    <Button
                        component={Link}
                        to="/"
                        color="inherit"
                    >
                        {(() => {
                            switch (type) {
                                case 'eighteen-plus': {
                                    return "I'm under 18";
                                }
                                case 'twenty-one-plus': {
                                    return "I'm under 21";
                                }
                                case 'twenty-five-plus': {
                                    return "I'm under 25";
                                }
                                default: {
                                    return "Go back";
                                }
                            }
                        })()}
                    </Button>
                </Stack>
            </Container>
        </Paper>
    );
};