import { ApolloError, gql, MutationFunction, MutationResult } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { Backdrop, Box, Card, CardActionArea, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { setUser } from '../redux/auth/actions';
import { RootState } from '../redux/configureStore';

const UPDATE_CURRENT_USER = gql`
    mutation UpdateCurrentUser($theme: String) {
        updateCurrentUser(input: {
            theme: $theme
        }) {
            id
            name
            username
            profileImage
            bio
            contentWarning
            theme
            email
            emailVerified
        }
    }
`;

const themes = [
    {
        name: 'Default',
        code: 'light',
        template: '/static/images/themes/light.png'
    },
    {
        name: 'Dark',
        code: 'dark',
        template: '/static/images/themes/dark.png'
    }
];

type AppearanceProps = {
    setUser: (user: any) => void;
    user: any;
};

const mapStateToProps = (state: RootState) => ({
    user: state.auth.user
});

const mapDispatchToProps = {
    setUser
};

export const Appearance = connect(mapStateToProps, mapDispatchToProps)((props: AppearanceProps): JSX.Element => {
    const {
        setUser,
        user
    } = props;

    return (
        <Fragment>
            <Helmet>
                <title>
                    Appearance - Paiz.io
                </title>
            </Helmet>
            <Container maxWidth="sm">
                <Mutation
                    mutation={UPDATE_CURRENT_USER}
                    variables={{

                    }}
                    onError={(err: ApolloError) => {

                    }}
                    onCompleted={(data: any) => {
                        setUser(data.updateCurrentUser);
                    }}
                >
                    {(mutate: MutationFunction, { loading }: MutationResult) => (
                        <Box
                            margin={{
                                xs: '32px 0 16px',
                                sm: '32px 0 24px'
                            }}
                        >
                            <Backdrop
                                open={loading}
                                sx={{
                                    zIndex: theme => theme.zIndex.drawer + 1,
                                    color: 'text.primary'
                                }}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            <Typography
                                variant="h4"
                                textAlign="center"
                                fontSize={{
                                    xs: 24,
                                    md: 34
                                }}
                            >
                                Appearance
                            </Typography>
                            <Grid
                                spacing={2}
                                container
                                sx={{
                                    marginTop: 4
                                }}
                            >
                                {themes.map(({ name, code, template }, index) => (
                                    <Grid
                                        key={index}
                                        xs={6}
                                        sm={4}
                                        item
                                    >
                                        <Card
                                            elevation={0}
                                            sx={{
                                                backgroundColor: user.theme === code ? 'primary.main' : 'background.paper'
                                            }}
                                        >
                                            <CardActionArea
                                                disabled={loading}
                                                sx={{
                                                    padding: '4px'
                                                }}
                                                onClick={() => {
                                                    const variables = {
                                                        theme: code
                                                    };
                                                    mutate({
                                                        variables
                                                    });
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        paddingTop: '150%',
                                                        overflow: 'hidden',
                                                        borderRadius: '6px',
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center',
                                                        backgroundImage: `url(${template})`
                                                    }}
                                                />
                                            </CardActionArea>
                                        </Card>
                                        <Typography
                                            variant="body1"
                                            color="text.secondary"
                                            textAlign="center"
                                            sx={{
                                                marginTop: 1
                                            }}
                                        >
                                            {name}
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    )}
                </Mutation>
            </Container>
        </Fragment>
    );
});