import { Box, Container, Typography } from '@mui/material';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

export const TermsAndConditions = (): JSX.Element => {
    return (
        <Fragment>
            <Helmet>
                <title>
                    Terms and Conditions - Paiz.io
                </title>
            </Helmet>
            <Container maxWidth="xl">
                <Box
                    marginY={{
                        xs: 11,
                        sm: 16,
                        lg: 20
                    }}
                >
                    <Typography
                        variant="h4"
                        fontSize={{
                            xs: 24,
                            md: 34
                        }}
                    >
                        Terms and Conditions
                    </Typography>
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                            marginTop: 4
                        }}
                    >
                        Your account
                    </Typography>
                    <Typography
                        textAlign="justify"
                        gutterBottom
                    >
                        To create an account and become a Paiz user, you must be at least 18. If you're creating an account on behalf of someone else, you must have their permission to do so. You're responsible for your account and using it in a lawful way.
                    </Typography>
                    <Typography
                        textAlign="justify"
                        gutterBottom
                    >
                        When you create an account, you agree to comply with these Terms and that you're (i) over 18, and (ii) legally able to enter these Terms with us. You must provide us with accurate info about yourself - if anything changes, please let us know so we can update your details.
                    </Typography>
                    <Typography
                        textAlign="justify"
                        gutterBottom
                    >
                        If you're using Paiz on behalf of a business or individual, you agree that you're authorised by them to act and agree to these Terms on their behalf.
                    </Typography>
                    <Typography
                        textAlign="justify"
                        gutterBottom
                    >
                        You're responsible for anything that happens to your account, so keep your login details and password safe and don't share them with anyone. If you think your account has been compromised, please contact us immediately.
                    </Typography>
                    <Typography
                        textAlign="justify"
                        gutterBottom
                    >
                        You must not assign or transfer your account to someone else, or use your account (or allow it to be used by anyone) in a way which in our reasonable opinion, causes damage to Paiz or our reputation, or infringes another's rights or applicable laws and regulations.
                    </Typography>
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                            marginTop: 4
                        }}
                    >
                        Your username
                    </Typography>
                    <Typography
                        textAlign="justify"
                        gutterBottom
                    >
                        The username you choose must be appropriate for everyone and cannot infringe someone else's rights.
                    </Typography>
                    <Typography
                        textAlign="justify"
                        gutterBottom
                    >
                        We know the username you choose is very important to you. But it's also important that your username is appropriate for all audiences and doesn't infringe anyone's rights, including intellectual property rights (such as copyright and trade marks) ("IP Rights").
                    </Typography>
                    <Typography
                        textAlign="justify"
                        gutterBottom
                    >
                        You can't use the name of another person (such as a celebrity), brand or company, have a username that is offensive, vulgar or obscene, or create an account with a username that you have no connection with just to profit from it in the future (known as "Domain Squatting").
                    </Typography>
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                            marginTop: 4
                        }}
                    >
                        Your content
                    </Typography>
                    <Typography
                        textAlign="justify"
                        gutterBottom
                    >
                        We love your content and want to show it off. When you post content on Paiz, you give us the right to display, use, and share it.
                    </Typography>
                    <Typography
                        textAlign="justify"
                        gutterBottom
                    >
                        When you post content on your profile, you grant us a licence to (i) use, publicly display, distribute, modify, adapt and create derivative works of such content; and (ii) use your name, image, voice, photograph, likeness and any other personal attributes in the content; on the Platform and in our marketing in all media (such as our social channels and any other advertising). This licence is worldwide, royalty-free and perpetual, which means we can use your content anywhere in the world, without paying you fees, for as long as we like. You agree that you have all third party rights necessary to post the content on Paiz and to grant us this licence.
                    </Typography>
                    <Typography
                        textAlign="justify"
                        gutterBottom
                    >
                        You'll retain all of your rights in your content. But keep in mind that your content will be publicly accessible, and may be used and re-shared by others on Paiz and across the internet.
                    </Typography>
                    <Typography
                        textAlign="justify"
                        gutterBottom
                    >
                        Please don't share personal info that you don't want visible to the world, and never post social security numbers, passport details or similar info that could cause harm in the wrong hands. You may only post personal info relating to others where you have their consent and have kept a record of it.
                    </Typography>
                    <Typography
                        textAlign="justify"
                        gutterBottom
                    >
                        We don't have to monitor the accuracy, reliability or legality of your content, but we may choose to do so. We may modify, remove or restrict access to content at any time in line with these Terms or apply a sensitive content warning to content that we deem unsuitable for all audiences.
                    </Typography>
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                            marginTop: 4
                        }}
                    >
                        Our Platform
                    </Typography>
                    <Typography
                        textAlign="justify"
                        gutterBottom
                    >
                        We own the Platform, but give you a limited right to use it for sharing content and viewing and interacting with other users' content. We're not responsible for any content, products or services made available via other users' profiles.
                    </Typography>
                    <Typography
                        textAlign="justify"
                        gutterBottom
                    >
                        All rights (including IP Rights) in and to the Platform (excluding your content) (the "Paiz IP") are and will remain exclusively owned by Paiz or our licensors. You won't acquire any rights in the Paiz IP and you're not allowed to use it (including our brand name or logo) for any purpose (such as to imply a partnership with, or endorsement from Paiz), without our prior written approval.
                    </Typography>
                    <Typography
                        textAlign="justify"
                        gutterBottom
                    >
                        As a user, we grant you a limited, revocable, non-exclusive, non-transferable right to use the Platform to create, display, use, play, and upload content in accordance with these Terms. If we provide you with any images, icons, themes, fonts, videos, graphics, or other content, you must only use them on your profile and comply with any guidelines we make available to you.
                    </Typography>
                    <Typography
                        textAlign="justify"
                        gutterBottom
                    >
                        You must not remove, obscure or alter any proprietary notices or trade marks on the Platform, or make unauthorised copies of, reproduce, distribute, licence, sell, resell, modify, translate, disassemble, decompile, decrypt, reverse engineer, create any derivative works from, or attempt to derive the source code of, the Platform or any part of it. If you visit Paiz profiles (a "profile visitor"), we grant you a limited, non-exclusive, non-transferable right to view and interact with the Platform via user profiles.
                    </Typography>
                    <Typography
                        textAlign="justify"
                    >
                        To the extent allowed by law, we're not responsible for any opinions, advice, statements, products, services, offers or other content posted by other users on their profiles.
                    </Typography>
                </Box>
            </Container>
        </Fragment>
    );
};