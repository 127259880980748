import { Box, Button, Card, CardActionArea, Chip, Container, Divider, Grid, Radio, Stack, SvgIcon, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import { Check } from 'react-feather';
import { Helmet } from 'react-helmet';

const features = [
    'Customizeable Paiz',
    'Unlimited links',
    'Unlimited Promoted links',
    '5% fee on Promoted links',
    'Advanced Analytics'
];

export const UpgradeToPro = (): JSX.Element => {
    const [
        plan,
        setPlan
    ] = useState('monthly');

    return (
        <Fragment>
            <Helmet>
                <title>
                    Upgrade to PRO - Paiz.io
                </title>
            </Helmet>
            <Container maxWidth="sm">
                <Box
                    margin={{
                        xs: '32px 0 16px',
                        sm: '32px 0 24px'
                    }}
                >
                    <Typography
                        variant="h4"
                        textAlign="center"
                        fontSize={{
                            xs: 24,
                            md: 34
                        }}
                    >
                        Upgrade to PRO
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            marginTop: 4
                        }}
                    >
                        Select your plan
                    </Typography>
                    <Grid
                        spacing={2}
                        container
                        sx={{
                            marginTop: -1
                        }}
                    >
                        <Grid
                            xs={12}
                            sm={6}
                            item
                        >
                            <Card
                                variant="outlined"
                                sx={{
                                    borderColor: plan === 'monthly' ? 'primary.main' : 'divider'
                                }}
                            >
                                <CardActionArea
                                    onClick={() => {
                                        setPlan('monthly');
                                    }}
                                >
                                    <Box padding={1}>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <Radio
                                                checked={plan === 'monthly'}
                                                disableRipple
                                            />
                                            <Typography variant="subtitle1">
                                                Monthly
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            item
                        >
                            <Card
                                variant="outlined"
                                sx={{
                                    borderColor: plan === 'annual' ? 'primary.main' : 'divider'
                                }}
                            >
                                <CardActionArea
                                    onClick={() => {
                                        setPlan('annual');
                                    }}
                                >
                                    <Box padding={1}>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <Radio
                                                checked={plan === 'annual'}
                                                disableRipple
                                            />
                                            <Stack
                                                justifyContent="space-between"
                                                alignItems="center"
                                                direction="row"
                                                spacing={1}
                                                sx={{
                                                    flex: 1,
                                                    paddingRight: '9px'
                                                }}
                                            >
                                                <Typography variant="subtitle1">
                                                    Annual
                                                </Typography>
                                                <Chip
                                                    variant="outlined"
                                                    size="small"
                                                    label="Best value"
                                                    color="secondary"
                                                />
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            marginTop: 4
                        }}
                    >
                        What you get
                    </Typography>
                    <Stack
                        spacing={1}
                        sx={{
                            marginTop: 1
                        }}
                    >
                        {features.map((feature, index) => (
                            <Stack
                                key={index}
                                alignItems="center"
                                direction="row"
                                spacing={1}
                            >
                                <SvgIcon color="primary">
                                    <Check />
                                </SvgIcon>
                                <Typography variant="body1">
                                    {feature}
                                </Typography>
                            </Stack>
                        ))}
                    </Stack>
                    <Divider
                        sx={{
                            marginY: 4
                        }}
                    />
                    <Stack
                        justifyContent="space-between"
                        alignItems="center"
                        direction="row"
                        spacing={1}
                    >
                        <Typography
                            color="text.secondary"
                            fontStyle="italic"
                        >
                            *Will be available soon
                        </Typography>
                        <Button
                            variant="contained"
                            disableElevation
                            disabled
                        >
                            Upgrade to PRO
                        </Button>
                    </Stack>
                </Box>
            </Container>
        </Fragment>
    );
};