import { Box, Button, Container, Stack, Typography, useTheme } from '@mui/material';
import { Fragment, useEffect } from 'react';
import { CheckCircle } from 'react-feather';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setAccessToken, setUser } from '../redux/auth/actions';

type VerifiedProps = {
    setAccessToken: (accessToken: string | null) => void;
    setUser: (user: any) => void;
};

const mapDispatchToProps = {
    setAccessToken,
    setUser
};

export const Verified = connect(null, mapDispatchToProps)((props: VerifiedProps): JSX.Element => {
    const { palette } = useTheme();
    const {
        setAccessToken,
        setUser
    } = props;

    useEffect(() => {
        setAccessToken(null);
        setUser(null);
    }, []);

    return (
        <Fragment>
            <Helmet>
                <title>
                    Verified - Paiz.io
                </title>
            </Helmet>
            <Container maxWidth="xl">
                <Box
                    marginY={{
                        xs: 11,
                        sm: 16,
                        lg: 20
                    }}
                >
                    <Stack alignItems="center">
                        <CheckCircle
                            size={96}
                            color={palette.success.main}
                        />
                        <Typography
                            variant="h4"
                            textAlign="center"
                            fontSize={{
                                xs: 24,
                                md: 34
                            }}
                            sx={{
                                marginTop: 4
                            }}
                        >
                            Your account has been verified
                        </Typography>
                        <Button
                            component={Link}
                            to="/signin"
                            variant="contained"
                            color="primary"
                            disableElevation
                            sx={{
                                marginTop: 3
                            }}
                        >
                            Sign in to continue
                        </Button>
                    </Stack>
                </Box>
            </Container>
        </Fragment>
    );
});