import { gql, useQuery } from '@apollo/client';
import { AppBar, Avatar, Box, Button, Card, Container, CssBaseline, Grid, IconButton, Link as MuiLink, Stack, SvgIcon, Toolbar, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { default as queryString } from 'query-string';
import { Fragment, useState } from 'react';
import { Share, User } from 'react-feather';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { default as Youtube } from 'react-youtube';
import { BrandDiscord, BrandFacebook, BrandInstagram, BrandLinkedin, BrandSnapchat, BrandSoundcloud, BrandTiktok, BrandTwitch, BrandTwitter, BrandYoutube, Mail, Phone } from 'tabler-icons-react';
import { dark } from '../account-themes/dark';
import { light } from '../account-themes/light';
import { Spacer } from '../components/common/Spacer';
import { Fetching } from '../components/Fetching';
import { SensitiveContentWarning } from '../components/SensitiveContentWarning';
import { ShareDialog } from '../components/ShareDialog';

const USER_BY_USERNAME = gql`
    query UserByUsername($username: String!) {
        userByUsername(username: $username) {
            id
            name
            username
            profileImage
            bio
            contentWarning
            theme
            verified
            emailVerified
            socialIcons {
                edges {
                    id
                    provider
                    url
                }
            }
            addons (
                sortBy: "order"
                sortOrder: "asc"
                where: {
                    visible: true
                }
            ) {
                edges {
                    id
                    type
                    title
                    url
                    thumbnail
                    order
                    visible
                }
            }
        }
    }
`;

const socialIconOrders: string[] = [
    'email',
    'phone',
    'linkedin',
    'twitter',
    'facebook',
    'instagram',
    'tiktok',
    'youtube',
    'twitch',
    'discord',
    'snapchat',
    'soundcloud'
];

const themes = {
    dark,
    light
};

type UserType = {
    id: string;
    name?: string;
    username: string;
    profileImage?: string;
    bio?: string;
    contentWarning: 'none' | 'sensitive' | 'eighteen-plus' | 'twenty-one-plus' | 'twenty-five-plus';
    theme: 'dark' | 'light';
    verified: boolean;
    emailVerified: boolean;
    socialIcons: {
        edges: [{
            id: string;
            provider: 'email' | 'phone' | 'discord' | 'facebook' | 'instagram' | 'linkedin' | 'snapchat' | 'soundcloud' | 'tiktok' | 'twitch' | 'twitter' | 'youtube';
            url: string;
        }];
    };
    addons: {
        edges: [{
            id: string;
            type: string;
            title: string;
            url?: string;
            thumbnail?: string;
            order: number;
            visible: boolean;
        }];
    };
};

export const Paiz = (): JSX.Element => {
    const { username } = useParams();
    const {
        data,
        loading,
        error
    } = useQuery<{ userByUsername: UserType; }>(USER_BY_USERNAME, {
        onCompleted: ({ userByUsername }) => setWarning(userByUsername && userByUsername.contentWarning !== 'none'),
        variables: {
            username
        }
    });

    const [
        open,
        setOpen
    ] = useState(false);
    const [
        warning,
        setWarning
    ] = useState(false);

    const socialIconsSorter = (a: any, b: any) => {
        return socialIconOrders.indexOf(a.provider) - socialIconOrders.indexOf(b.provider);
    };

    if (error) {
        throw new Response(null, {
            status: 500,
            statusText: 'Internal server error'
        });
    }

    if (data === undefined) {
        return (
            <Fetching />
        );
    }

    // if (data.userByUsername === null || data.userByUsername.emailVerified === false) {
    if (data.userByUsername === null) {
        throw new Response(null, {
            status: 404,
            statusText: 'Page not found'
        });
    }

    return (
        <ThemeProvider theme={themes[data.userByUsername.theme]}>
            <CssBaseline />
            <Helmet>
                <meta name="theme-color" content={themes[data.userByUsername.theme].palette.background.default} />
                <meta name="description" content={data.userByUsername.bio} />
                <meta property="og:site_name" content="Paiz" />
                <meta property="og:title" content={data.userByUsername.username} />
                <meta property="og:description" content={data.userByUsername.bio} />
                <meta property="og:url" content={`https://paiz.io/${username}`} />
                <meta property="og:image" content={data.userByUsername.profileImage ? `${process.env.REACT_APP_CDN_URL}/fit-in/600x600/${data.userByUsername.profileImage}` : '/static/images/default-og-image.png'} />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="profile" />
                <meta property="profile:username" content={data.userByUsername.username} />
                <meta name="twitter:title" content={data.userByUsername.username} />
                <meta name="twitter:description" content={data.userByUsername.bio} />
                <meta name="twitter:image" content={data.userByUsername.profileImage ? `${process.env.REACT_APP_CDN_URL}/fit-in/600x600/${data.userByUsername.profileImage}` : '/static/images/default-og-image.png'} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:domain" content="Paiz" />
                <title>
                    {data.userByUsername.username} | Paiz.io
                </title>
            </Helmet>
            {warning
                ?
                <SensitiveContentWarning
                    type={data.userByUsername.contentWarning}
                    onContinue={() => {
                        setWarning(false);
                    }}
                />
                :
                <Fragment>
                    {username && (
                        <ShareDialog
                            username={username}
                            open={open}
                            onClose={() => {
                                setOpen(false);
                            }}
                        />
                    )}
                    <AppBar
                        position="fixed"
                        color="transparent"
                        elevation={0}
                        enableColorOnDark
                        sx={{
                            marginTop: 2
                        }}
                    >
                        <Container maxWidth="xl">
                            <Toolbar
                                disableGutters
                                sx={{
                                    minHeight: '56px !important',
                                    padding: '0 6px',
                                    borderRadius: 4,
                                    border: theme => `1px solid ${theme.palette.divider}`,
                                    backgroundColor: 'background.paper'
                                }}
                            >
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    {data.userByUsername.profileImage
                                        ?
                                        <Avatar
                                            src={`${process.env.REACT_APP_CDN_URL}/fit-in/160x160/${data.userByUsername.profileImage}`}
                                            sx={{
                                                width: 42,
                                                height: 42
                                            }}
                                        />
                                        :
                                        <Avatar
                                            sx={{
                                                width: 42,
                                                height: 42,
                                                backgroundColor: 'background.default',
                                                color: 'text.disabled'
                                            }}
                                        >
                                            <User />
                                        </Avatar>
                                    }
                                    <Typography
                                        variant="subtitle1"
                                        textAlign="center"
                                        textTransform="lowercase"
                                    >
                                        <Box component="span" sx={{ color: 'text.secondary' }}>paiz.io/</Box>{data.userByUsername.username}
                                    </Typography>
                                </Stack>
                                <Spacer />
                                <Box color="text.primary">
                                    <IconButton
                                        color="inherit"
                                        onClick={() => {
                                            setOpen(true);
                                        }}
                                        sx={{
                                            border: theme => `1px solid ${theme.palette.divider}`,
                                            backgroundColor: 'background.default'
                                        }}
                                    >
                                        <SvgIcon>
                                            <Share strokeWidth={1} />
                                        </SvgIcon>
                                    </IconButton>
                                </Box>
                            </Toolbar>
                        </Container>
                    </AppBar>
                    <Container
                        maxWidth={false}
                        sx={{
                            maxWidth: 728
                        }}
                    >
                        <Box
                            sx={{
                                marginTop: 13,
                                marginBottom: 4
                            }}
                        >
                            <Box
                                sx={{
                                    minHeight: 'calc(100vh - 232px)'
                                }}
                            >
                                <Stack alignItems="center">
                                    {data.userByUsername.profileImage
                                        ?
                                        <Avatar
                                            src={`${process.env.REACT_APP_CDN_URL}/fit-in/320x320/${data.userByUsername.profileImage}`}
                                            sx={{
                                                width: 96,
                                                height: 96
                                            }}
                                        />
                                        :
                                        <Avatar
                                            sx={{
                                                width: 96,
                                                height: 96,
                                                backgroundColor: 'background.paper',
                                                color: 'text.disabled'
                                            }}
                                        >
                                            <User size={34} />
                                        </Avatar>
                                    }
                                </Stack>
                                <Stack
                                    justifyContent="center"
                                    alignItems="center"
                                    direction="row"
                                    spacing={0.5}
                                    sx={{
                                        marginTop: 2
                                    }}
                                >
                                    <Typography
                                        component="h1"
                                        variant="h6"
                                        textAlign="center"
                                        textTransform="uppercase"
                                        fontSize={{
                                            xs: 16,
                                            md: 20
                                        }}
                                    >
                                        {data.userByUsername.name || data.userByUsername.username}
                                    </Typography>
                                    {data.userByUsername.verified && (
                                        <Box
                                            component="img"
                                            src="/static/images/verified.png"
                                            sx={{
                                                width: 16
                                            }}
                                        />
                                    )}
                                </Stack>
                                {data.userByUsername.bio && (
                                    <Typography
                                        variant="body1"
                                        color="text.secondary"
                                        textAlign="center"
                                        sx={{
                                            marginTop: '4px'
                                        }}
                                    >
                                        {data.userByUsername.bio}
                                    </Typography>
                                )}
                                {Boolean(data.userByUsername.socialIcons.edges.length) && (
                                    <Grid
                                        justifyContent="center"
                                        container
                                        sx={{
                                            marginTop: 3
                                        }}
                                    >
                                        {(data.userByUsername.socialIcons.edges as any[]).slice().sort(socialIconsSorter).map(socialIcon => (
                                            <Fragment key={socialIcon.id}>
                                                {(() => {
                                                    switch (socialIcon.provider) {
                                                        case 'email': {
                                                            return (
                                                                <Grid item>
                                                                    <IconButton
                                                                        component="a"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        color="inherit"
                                                                        href={`mailto:${socialIcon.url}`}
                                                                        sx={{
                                                                            fontSize: 32
                                                                        }}
                                                                    >
                                                                        <SvgIcon fontSize="inherit">
                                                                            <Mail strokeWidth={1} />
                                                                        </SvgIcon>
                                                                    </IconButton>
                                                                </Grid>
                                                            );
                                                        }
                                                        case 'phone': {
                                                            return (
                                                                <Grid item>
                                                                    <IconButton
                                                                        component="a"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        color="inherit"
                                                                        href={`tel:${socialIcon.url}`}
                                                                        sx={{
                                                                            fontSize: 32
                                                                        }}
                                                                    >
                                                                        <SvgIcon fontSize="inherit">
                                                                            <Phone strokeWidth={1} />
                                                                        </SvgIcon>
                                                                    </IconButton>
                                                                </Grid>
                                                            );
                                                        }
                                                        case 'linkedin': {
                                                            return (
                                                                <Grid item>
                                                                    <IconButton
                                                                        component="a"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        color="inherit"
                                                                        href={socialIcon.url}
                                                                        sx={{
                                                                            fontSize: 32
                                                                        }}
                                                                    >
                                                                        <SvgIcon fontSize="inherit">
                                                                            <BrandLinkedin strokeWidth={1} />
                                                                        </SvgIcon>
                                                                    </IconButton>
                                                                </Grid>
                                                            );
                                                        }
                                                        case 'twitter': {
                                                            return (
                                                                <Grid item>
                                                                    <IconButton
                                                                        component="a"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        color="inherit"
                                                                        href={`https://twitter.com/${socialIcon.url}`}
                                                                        sx={{
                                                                            fontSize: 32
                                                                        }}
                                                                    >
                                                                        <SvgIcon fontSize="inherit">
                                                                            <BrandTwitter strokeWidth={1} />
                                                                        </SvgIcon>
                                                                    </IconButton>
                                                                </Grid>
                                                            );
                                                        }
                                                        case 'facebook': {
                                                            return (
                                                                <Grid item>
                                                                    <IconButton
                                                                        component="a"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        color="inherit"
                                                                        href={socialIcon.url}
                                                                        sx={{
                                                                            fontSize: 32
                                                                        }}
                                                                    >
                                                                        <SvgIcon fontSize="inherit">
                                                                            <BrandFacebook strokeWidth={1} />
                                                                        </SvgIcon>
                                                                    </IconButton>
                                                                </Grid>
                                                            );
                                                        }
                                                        case 'instagram': {
                                                            return (
                                                                <Grid item>
                                                                    <IconButton
                                                                        component="a"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        color="inherit"
                                                                        href={`https://instagram.com/${socialIcon.url}`}
                                                                        sx={{
                                                                            fontSize: 32
                                                                        }}
                                                                    >
                                                                        <SvgIcon fontSize="inherit">
                                                                            <BrandInstagram strokeWidth={1} />
                                                                        </SvgIcon>
                                                                    </IconButton>
                                                                </Grid>
                                                            );
                                                        }
                                                        case 'tiktok': {
                                                            return (
                                                                <Grid item>
                                                                    <IconButton
                                                                        component="a"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        color="inherit"
                                                                        href={`https://tiktok.com/${socialIcon.url}`}
                                                                        sx={{
                                                                            fontSize: 32
                                                                        }}
                                                                    >
                                                                        <SvgIcon fontSize="inherit">
                                                                            <BrandTiktok strokeWidth={1} />
                                                                        </SvgIcon>
                                                                    </IconButton>
                                                                </Grid>
                                                            );
                                                        }
                                                        case 'youtube': {
                                                            return (
                                                                <Grid item>
                                                                    <IconButton
                                                                        component="a"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        color="inherit"
                                                                        href={socialIcon.url}
                                                                        sx={{
                                                                            fontSize: 32
                                                                        }}
                                                                    >
                                                                        <SvgIcon fontSize="inherit">
                                                                            <BrandYoutube strokeWidth={1} />
                                                                        </SvgIcon>
                                                                    </IconButton>
                                                                </Grid>
                                                            );
                                                        }
                                                        case 'twitch': {
                                                            return (
                                                                <Grid item>
                                                                    <IconButton
                                                                        component="a"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        color="inherit"
                                                                        href={socialIcon.url}
                                                                        sx={{
                                                                            fontSize: 32
                                                                        }}
                                                                    >
                                                                        <SvgIcon fontSize="inherit">
                                                                            <BrandTwitch strokeWidth={1} />
                                                                        </SvgIcon>
                                                                    </IconButton>
                                                                </Grid>
                                                            );
                                                        }
                                                        case 'discord': {
                                                            return (
                                                                <Grid item>
                                                                    <IconButton
                                                                        component="a"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        color="inherit"
                                                                        href={socialIcon.url}
                                                                        sx={{
                                                                            fontSize: 32
                                                                        }}
                                                                    >
                                                                        <SvgIcon fontSize="inherit">
                                                                            <BrandDiscord strokeWidth={1} />
                                                                        </SvgIcon>
                                                                    </IconButton>
                                                                </Grid>
                                                            );
                                                        }
                                                        case 'snapchat': {
                                                            return (
                                                                <Grid item>
                                                                    <IconButton
                                                                        component="a"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        color="inherit"
                                                                        href={socialIcon.url}
                                                                        sx={{
                                                                            fontSize: 32
                                                                        }}
                                                                    >
                                                                        <SvgIcon fontSize="inherit">
                                                                            <BrandSnapchat strokeWidth={1} />
                                                                        </SvgIcon>
                                                                    </IconButton>
                                                                </Grid>
                                                            );
                                                        }
                                                        case 'soundcloud': {
                                                            return (
                                                                <Grid item>
                                                                    <IconButton
                                                                        component="a"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        color="inherit"
                                                                        href={socialIcon.url}
                                                                        sx={{
                                                                            fontSize: 32
                                                                        }}
                                                                    >
                                                                        <SvgIcon fontSize="inherit">
                                                                            <BrandSoundcloud strokeWidth={1} />
                                                                        </SvgIcon>
                                                                    </IconButton>
                                                                </Grid>
                                                            );
                                                        }
                                                    }
                                                })()}
                                            </Fragment>
                                        ))}
                                    </Grid>
                                )}
                                {Boolean(data.userByUsername.addons.edges.length) && (
                                    <Stack
                                        spacing={1}
                                        sx={{
                                            marginTop: 4
                                        }}
                                    >
                                        {data.userByUsername.addons.edges.map((addon: any) => (
                                            <Fragment key={addon.id}>
                                                {(() => {
                                                    switch (addon.type) {
                                                        case 'youtube': {
                                                            const { v } = queryString.parse(addon.url.slice(addon.url.indexOf('watch?') + 6, addon.url.length));
                                                            return (
                                                                <Card
                                                                    elevation={0}
                                                                    sx={{
                                                                        position: 'relative',
                                                                        overflow: 'hidden',
                                                                        paddingTop: '56.25%',
                                                                        clipPath: theme => `inset(0 0 0 0 round ${theme.shape.borderRadius}px)`
                                                                    }}
                                                                >
                                                                    <Youtube
                                                                        videoId={v as string}
                                                                        opts={{
                                                                            width: '100%',
                                                                            height: '100%'
                                                                        }}
                                                                        style={{
                                                                            position: 'absolute',
                                                                            inset: 0
                                                                        }}
                                                                    />
                                                                </Card>
                                                            );
                                                        }
                                                        case 'v-card': {
                                                            return (
                                                                <Button
                                                                    component="a"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    size="large"
                                                                    href={`${process.env.REACT_APP_API_URL}/v-card/download/${addon.id}`}
                                                                    download
                                                                    disableElevation
                                                                    fullWidth
                                                                    sx={{
                                                                        paddingY: '14px'
                                                                    }}
                                                                >
                                                                    {addon.title}
                                                                </Button>
                                                            );
                                                        }
                                                        default: {
                                                            return (
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    size="large"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    href={addon.url}
                                                                    disableElevation
                                                                    fullWidth
                                                                    sx={{
                                                                        paddingY: '14px'
                                                                    }}
                                                                >
                                                                    {addon.title}
                                                                </Button>
                                                            );
                                                        }
                                                    }
                                                })()}
                                            </Fragment>
                                        ))}
                                    </Stack>
                                )}
                            </Box>
                            <Stack
                                alignItems="center"
                                sx={{
                                    marginTop: 4
                                }}
                            >
                                <MuiLink
                                    className="clearfix"
                                    component={Link}
                                    to="/"
                                    underline="none"
                                >
                                    <Box
                                        component="img"
                                        alt="paiz logo"
                                        src="/static/images/logo.png"
                                        sx={{
                                            display: 'inline-block',
                                            height: 40,
                                            float: 'left'
                                        }}
                                    />
                                </MuiLink>
                            </Stack>
                        </Box>
                    </Container>
                </Fragment>
            }
        </ThemeProvider>
    );
};