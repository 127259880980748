import { Box, Dialog, DialogContent, DialogProps, DialogTitle, InputAdornment, Stack, TextField } from '@mui/material';
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton } from 'react-share';
import { CopyButton } from './common/CopyButton';
import { PaizQr } from './PaizQr';

type ShareDialogProps = DialogProps & {
    username: string;
};

export const ShareDialog = ({ username, ...props }: ShareDialogProps): JSX.Element => {
    const url = `${window.location.origin}/${username}`;

    return (
        <Dialog
            {...props}
            maxWidth="xs"
            fullWidth
            PaperProps={{
                sx: {
                    margin: 2,
                    width: theme => `calc(100% - ${theme.spacing(4)})`
                }
            }}
        >
            <DialogTitle textAlign="center">
                Share
            </DialogTitle>
            <DialogContent>
                <Box marginTop={3}>
                    <PaizQr username={username} />
                </Box>
                <TextField
                    value={url}
                    variant="outlined"
                    fullWidth
                    sx={{
                        marginTop: 4
                    }}
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <CopyButton
                                    edge="end"
                                    text={url}
                                />
                            </InputAdornment>
                        )
                    }}
                />
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                    sx={{
                        marginTop: 4
                    }}
                >
                    <FacebookShareButton url={url}>
                        <FacebookIcon
                            size={40}
                            style={{
                                borderRadius: '8px 0 0 8px'
                            }}
                        />
                    </FacebookShareButton>
                    <TwitterShareButton url={url}>
                        <TwitterIcon
                            size={40}
                        />
                    </TwitterShareButton>
                    <LinkedinShareButton url={url}>
                        <LinkedinIcon
                            size={40}
                            style={{
                                borderRadius: '0 8px 8px 0'
                            }}
                        />
                    </LinkedinShareButton>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};