import { Avatar, Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Chip, Container, Grid, IconButton, InputAdornment, Skeleton, Stack, SvgIcon, TextField, Typography } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { Edit, Menu, Trash, TrendingDown, TrendingUp } from 'react-feather';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Element } from 'react-scroll';
import { default as TextTransition, presets } from 'react-text-transition';
import { Accordion } from '../components/common/Accordion';
import { PricingPlan } from '../components/PricingPlan';
import { RootState } from '../redux/configureStore';


type CelebrityProps = {
    children: ReactNode;
};

const Celebrity = ({ children }: CelebrityProps) => (
    <TextTransition
        springConfig={presets.default}
        inline
    >
        {children}
    </TextTransition>
);

const CELEBRITIES = [
    'gremix',
    'CTS',
    'anguuch',
    'neo'
];

type HomeProps = {
    mode: 'dark' | 'light';
};

const mapStateToProps = (state: RootState) => ({
    mode: state.theme.mode
});

export const Home = connect(mapStateToProps)((props: HomeProps): JSX.Element => {
    const [
        username,
        usernameSetter
    ] = useState('');
    const [
        index,
        indexSetter
    ] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            indexSetter(index => index + 1);
        }, 2500);
        return () => {
            clearTimeout(intervalId);
        };
    }, []);

    return (
        <div>
            <Helmet>
                <title>
                    Paiz.io
                </title>
            </Helmet>
            <Box
                sx={{
                    padding: {
                        xs: '192px 0 0',
                        sm: '256px 0 0',
                        lg: '128px 0 0'
                    }
                }}
            >
                <Container maxWidth="xl">
                    <Grid
                        columnSpacing={4}
                        rowSpacing={8}
                        justifyContent="center"
                        alignItems="center"
                        container
                    >
                        <Grid
                            xs={12}
                            sm={10}
                            lg={8}
                            item
                        >
                            <Typography
                                component="h1"
                                variant="h2"
                                textAlign={{
                                    xs: 'left',
                                    sm: 'center',
                                    lg: 'left'
                                }}
                                fontWeight={800}
                                fontSize={{
                                    xs: 34,
                                    sm: 48,
                                    md: 60
                                }}
                            >
                                Sell anything,<br />Build your own link
                            </Typography>
                            <Typography
                                variant="h6"
                                color="text.secondary"
                                textAlign={{
                                    xs: 'left',
                                    sm: 'center',
                                    lg: 'left'
                                }}
                                fontWeight={300}
                                fontSize={{
                                    xs: 16,
                                    sm: 20
                                }}
                                sx={{
                                    marginTop: {
                                        xs: 2,
                                        sm: 4
                                    }
                                }}
                            >
                                Build a beautiful site for all <Box component="strong" sx={{ color: 'text.primary' }}>your links, products, services, appointment</Box> and more.
                            </Typography>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}
                                sx={{
                                    marginTop: {
                                        xs: 4,
                                        sm: 6
                                    }
                                }}
                            >
                                <TextField
                                    placeholder="username"
                                    value={username}
                                    onChange={e => usernameSetter(e.target.value)}
                                    sx={{
                                        width: '100%',
                                        maxWidth: '400px',
                                        marginX: {
                                            xs: 0,
                                            sm: 'auto',
                                            lg: 0
                                        }
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                paiz.io/
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Button
                                                    component={Link}
                                                    to={`/signup?username=${username}`}
                                                    variant="contained"
                                                    size="large"
                                                    disableElevation
                                                >
                                                    Claim
                                                </Button>
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            paddingRight: '7px',
                                            backgroundColor: 'background.paper'
                                        }
                                    }}
                                />
                            </Stack>
                        </Grid>
                        <Grid
                            xs={12}
                            lg={4}
                            item
                        >
                            <Box
                                sx={{
                                    marginX: 'auto',
                                    width: {
                                        xs: '100%',
                                        sm: '50%',
                                        lg: '80%'
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'relative',
                                        width: '100%',
                                        paddingTop: '216%'
                                    }}
                                >
                                    <iframe
                                        src={`${window.location.origin}/paiz.io`}
                                        title="paiz.io/paiz.io"
                                        width="100%"
                                        height="100%"
                                        style={{
                                            position: 'absolute',
                                            inset: 0,
                                            border: 'none',
                                            borderRadius: 8,
                                            boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.1)'
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Container maxWidth="xl">
                <div id="features">
                    <Element name="features">
                        <Box
                            marginY={{
                                xs: 8,
                                sm: 12,
                                md: 16,
                                lg: 20
                            }}
                        >
                            <Grid
                                columnSpacing={{
                                    xs: 4,
                                    md: 10
                                }}
                                rowSpacing={4}
                                alignItems="center"
                                direction={{
                                    xs: 'row',
                                    sm: 'row-reverse'
                                }}
                                container
                            >
                                <Grid
                                    xs={12}
                                    sm={6}
                                    item
                                >
                                    <Typography
                                        variant="h4"
                                        fontSize={{
                                            xs: 24,
                                            md: 34
                                        }}
                                    >
                                        Build your Paiz in<br />minutes
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color="text.secondary"
                                        sx={{
                                            marginTop: 2
                                        }}
                                    >
                                        Connect your socials, website, store, videos, music, podcast, events and more. It all comes together in a link in bio landing page designed to convert.
                                    </Typography>
                                    <Button
                                        component={Link}
                                        to="/signup"
                                        variant="contained"
                                        size="large"
                                        disableElevation
                                        sx={{
                                            marginTop: 4
                                        }}
                                    >
                                        Get started
                                    </Button>
                                </Grid>
                                <Grid
                                    xs={12}
                                    sm={6}
                                    item
                                >
                                    <Stack
                                        spacing={1}
                                        sx={{
                                            marginX: 'auto',
                                            maxWidth: 440
                                        }}
                                    >
                                        <Card elevation={0}>
                                            <Box padding={2}>
                                                <Grid
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    wrap="nowrap"
                                                    columnSpacing={2}
                                                    container
                                                >
                                                    <Grid item>
                                                        <IconButton
                                                            size="small"
                                                            disableRipple
                                                        >
                                                            <SvgIcon htmlColor="#6a6c6f">
                                                                <Menu />
                                                            </SvgIcon>
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs
                                                        sx={{
                                                            overflow: 'hidden'
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="subtitle1"
                                                            noWrap
                                                        >
                                                            Save my contact
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            color="text.secondary"
                                                            noWrap
                                                        >
                                                            https://paiz.io/yourusername
                                                        </Typography>
                                                        <Chip
                                                            label="contact details"
                                                            variant="outlined"
                                                            color="secondary"
                                                            size="small"
                                                            sx={{
                                                                marginTop: 1
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Stack
                                                            alignItems="center"
                                                            direction="row"
                                                            spacing={1}
                                                        >
                                                            <IconButton size="small">
                                                                <SvgIcon htmlColor="#6a6c6f">
                                                                    <Edit />
                                                                </SvgIcon>
                                                            </IconButton>
                                                            <IconButton size="small">
                                                                <SvgIcon htmlColor="#6a6c6f">
                                                                    <Trash />
                                                                </SvgIcon>
                                                            </IconButton>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Card>
                                        <Card elevation={0}>
                                            <Box padding={2}>
                                                <Grid
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    wrap="nowrap"
                                                    columnSpacing={2}
                                                    container
                                                >
                                                    <Grid item>
                                                        <IconButton
                                                            size="small"
                                                            disableRipple
                                                        >
                                                            <SvgIcon htmlColor="#6a6c6f">
                                                                <Menu />
                                                            </SvgIcon>
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs
                                                        sx={{
                                                            overflow: 'hidden'
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="subtitle1"
                                                            noWrap
                                                        >
                                                            My website
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            color="text.secondary"
                                                            noWrap
                                                        >
                                                            https://yourwebsite.com
                                                        </Typography>
                                                        <Chip
                                                            label="link"
                                                            variant="outlined"
                                                            color="secondary"
                                                            size="small"
                                                            sx={{
                                                                marginTop: 1
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Stack
                                                            alignItems="center"
                                                            direction="row"
                                                            spacing={1}
                                                        >
                                                            <IconButton size="small">
                                                                <SvgIcon htmlColor="#6a6c6f">
                                                                    <Edit />
                                                                </SvgIcon>
                                                            </IconButton>
                                                            <IconButton size="small">
                                                                <SvgIcon htmlColor="#6a6c6f">
                                                                    <Trash />
                                                                </SvgIcon>
                                                            </IconButton>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Card>
                                        <Card elevation={0}>
                                            <Box padding={2}>
                                                <Grid
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    wrap="nowrap"
                                                    columnSpacing={2}
                                                    container
                                                >
                                                    <Grid item>
                                                        <IconButton
                                                            size="small"
                                                            disableRipple
                                                        >
                                                            <SvgIcon htmlColor="#6a6c6f">
                                                                <Menu />
                                                            </SvgIcon>
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs
                                                        sx={{
                                                            overflow: 'hidden'
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="subtitle1"
                                                            noWrap
                                                        >
                                                            Subscribe my channel
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            color="text.secondary"
                                                            noWrap
                                                        >
                                                            https://youtube.com/yourchannel
                                                        </Typography>
                                                        <Chip
                                                            label="link"
                                                            variant="outlined"
                                                            color="secondary"
                                                            size="small"
                                                            sx={{
                                                                marginTop: 1
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Stack
                                                            alignItems="center"
                                                            direction="row"
                                                            spacing={1}
                                                        >
                                                            <IconButton size="small">
                                                                <SvgIcon htmlColor="#6a6c6f">
                                                                    <Edit />
                                                                </SvgIcon>
                                                            </IconButton>
                                                            <IconButton size="small">
                                                                <SvgIcon htmlColor="#6a6c6f">
                                                                    <Trash />
                                                                </SvgIcon>
                                                            </IconButton>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Card>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Element>
                </div>
                <Box
                    marginY={{
                        xs: 8,
                        sm: 12,
                        md: 16,
                        lg: 20
                    }}
                >
                    <Grid
                        columnSpacing={{
                            xs: 4,
                            md: 10
                        }}
                        rowSpacing={4}
                        alignItems="center"
                        container
                    >
                        <Grid
                            xs={12}
                            sm={6}
                            item
                        >
                            <Typography
                                variant="h4"
                                fontSize={{
                                    xs: 24,
                                    md: 34
                                }}
                            >
                                Sell anything and<br />get paid
                            </Typography>
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                sx={{
                                    marginTop: 2
                                }}
                            >
                                Easily sell products or services & get paid – from fan requests, e-courses, content & more.
                            </Typography>
                            <Button
                                component={Link}
                                to="/signup"
                                variant="contained"
                                size="large"
                                disableElevation
                                sx={{
                                    marginTop: 4
                                }}
                            >
                                Get started
                            </Button>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            columnSpacing={1}
                            container
                            item
                        >
                            <Grid
                                item
                                xs
                            >
                                <Card elevation={0}>
                                    <CardMedia
                                        image="/static/images/paiz-tshirt.png"
                                        sx={{
                                            height: 200
                                        }}
                                    />
                                    <CardContent>
                                        <Typography variant="h6">
                                            Paiz t-shirt
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            color="text.secondary"
                                        >
                                            Morbi id rhoncus ante, aliquet mollis tellus. Ut sed pellentesque nunc.
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                marginTop: 1
                                            }}
                                        >
                                            $20
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            sx={{
                                                textTransform: 'uppercase'
                                            }}
                                        >
                                            Buy now
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                            <Grid
                                item
                                xs
                                sx={{
                                    display: {
                                        xs: 'none',
                                        md: 'block'
                                    }
                                }}
                            >
                                <Card elevation={0}>
                                    <CardMedia
                                        image="/static/images/follow-back.png"
                                        sx={{
                                            height: 200
                                        }}
                                    />
                                    <CardContent>
                                        <Typography variant="h6">
                                            Follow back on IG
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            color="text.secondary"
                                        >
                                            Morbi id rhoncus ante, aliquet mollis tellus. Ut sed pellentesque nunc.
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                marginTop: 1
                                            }}
                                        >
                                            $199
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            sx={{
                                                textTransform: 'uppercase'
                                            }}
                                        >
                                            Buy now
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    marginY={{
                        xs: 8,
                        sm: 12,
                        md: 16,
                        lg: 20
                    }}
                >
                    <Grid
                        columnSpacing={{
                            xs: 4,
                            md: 10
                        }}
                        rowSpacing={4}
                        alignItems="center"
                        direction={{
                            xs: 'row',
                            sm: 'row-reverse'
                        }}
                        container
                    >
                        <Grid
                            xs={12}
                            sm={6}
                            item
                        >
                            <Typography
                                variant="h4"
                                fontSize={{
                                    xs: 24,
                                    md: 34
                                }}
                            >
                                Create your digital<br />business card
                            </Typography>
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                sx={{
                                    marginTop: 2
                                }}
                            >
                                Easily sell products or services & get paid – from fan requests, e-courses, content & more.
                            </Typography>
                            <Button
                                component={Link}
                                to="/signup"
                                variant="contained"
                                size="large"
                                disableElevation
                                sx={{
                                    marginTop: 4
                                }}
                            >
                                Get started
                            </Button>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            item
                        >
                            <Stack alignItems="center">
                                <Box
                                    component="img"
                                    src="/static/images/paiz-qr.png"
                                    sx={{
                                        width: 210
                                    }}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    marginY={{
                        xs: 8,
                        sm: 12,
                        md: 16,
                        lg: 20
                    }}
                >
                    <Grid
                        columnSpacing={{
                            xs: 4,
                            md: 10
                        }}
                        rowSpacing={4}
                        alignItems="center"
                        container
                    >
                        <Grid
                            xs={12}
                            sm={6}
                            item
                        >
                            <Typography
                                variant="h4"
                                fontSize={{
                                    xs: 24,
                                    md: 34
                                }}
                            >
                                Share it everywhere
                            </Typography>
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                sx={{
                                    marginTop: 2
                                }}
                            >
                                Add your unique Paiz URL to all the platforms and places you find your audience. Then use your QR code to drive your offline traffic online.
                            </Typography>
                            <Grid
                                container
                                sx={{
                                    marginTop: 4
                                }}
                            >
                                <Grid
                                    spacing={4}
                                    xs={5}
                                    lg={3}
                                    container
                                    item
                                >
                                    {['instagram', 'tik-tok', 'facebook', 'twitter', 'youtube', 'twitch'].map(platform => (
                                        <Grid
                                            key={platform}
                                            xs={4}
                                            item
                                        >
                                            <Box
                                                component="img"
                                                src={`/static/images/${platform}.png`}
                                                alt={platform}
                                                sx={{
                                                    width: '100%',
                                                    filter: props.mode === 'dark' ? 'invert(100%)' : 'none',
                                                    opacity: props.mode === 'dark' ? '70%' : '60%'
                                                }}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            item
                        >
                            <Box
                                sx={{
                                    position: 'relative',
                                    marginX: 'auto',
                                    width: '100%',
                                    maxWidth: 390
                                }}
                            >
                                <Box
                                    component="img"
                                    src="/static/images/instagram-icon.png"
                                    sx={{
                                        position: 'absolute',
                                        inset: 'auto -16px -16px auto',
                                        zIndex: 999,
                                        width: 64,
                                        height: 64
                                    }}
                                />
                                <Card elevation={0}>
                                    <Box padding={3}>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Skeleton
                                                variant="circular"
                                                animation={false}
                                                width={80}
                                                height={80}
                                            />
                                            <Stack
                                                direction="row"
                                                justifyContent="center"
                                                spacing={1}
                                                sx={{
                                                    flex: 1
                                                }}
                                            >
                                                <Skeleton
                                                    variant="rounded"
                                                    animation={false}
                                                    height={34}
                                                    width="100%"
                                                />
                                                <Skeleton
                                                    variant="rounded"
                                                    animation={false}
                                                    height={34}
                                                    width="100%"
                                                />
                                                <Skeleton
                                                    variant="rounded"
                                                    animation={false}
                                                    height={34}
                                                    width="100%"
                                                />
                                            </Stack>
                                        </Stack>
                                        <Stack
                                            spacing={1}
                                            sx={{
                                                marginTop: 2
                                            }}
                                        >
                                            <Skeleton
                                                variant="rounded"
                                                animation={false}
                                                height={24}
                                                width={140}
                                            />
                                            <Card variant="outlined">
                                                <Box padding={1}>
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        spacing={1}
                                                    >
                                                        <Box
                                                            component="img"
                                                            src="/static/images/logo-symbol.png"
                                                            sx={{
                                                                height: 24
                                                            }}
                                                        />
                                                        <Typography
                                                            variant="body1"
                                                            color="text.secondary"
                                                        >
                                                            paiz.io/<Box component="strong" color="secondary.main">yourusername</Box>
                                                        </Typography>
                                                    </Stack>
                                                </Box>
                                            </Card>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={2}
                                            sx={{
                                                marginTop: 3
                                            }}
                                        >
                                            <Skeleton
                                                variant="circular"
                                                animation={false}
                                                width={56}
                                                height={56}
                                            />
                                            <Skeleton
                                                variant="circular"
                                                animation={false}
                                                width={56}
                                                height={56}
                                            />
                                            <Skeleton
                                                variant="circular"
                                                animation={false}
                                                width={56}
                                                height={56}
                                            />
                                        </Stack>
                                    </Box>
                                    <Stack
                                        direction="row"
                                        spacing={0.5}
                                    >
                                        <Skeleton
                                            variant="rectangular"
                                            animation={false}
                                            width="100%"
                                            height={120}
                                        />
                                        <Skeleton
                                            variant="rectangular"
                                            animation={false}
                                            width="100%"
                                            height={120}
                                        />
                                        <Skeleton
                                            variant="rectangular"
                                            animation={false}
                                            width="100%"
                                            height={120}
                                        />
                                    </Stack>
                                </Card>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    marginY={{
                        xs: 8,
                        sm: 12,
                        md: 16,
                        lg: 20
                    }}
                >
                    <Grid
                        columnSpacing={{
                            xs: 4,
                            md: 10
                        }}
                        rowSpacing={4}
                        alignItems="center"
                        direction={{
                            xs: 'row',
                            sm: 'row-reverse'
                        }}
                        container
                    >
                        <Grid
                            xs={12}
                            sm={6}
                            item
                        >
                            <Typography
                                variant="h4"
                                fontSize={{
                                    xs: 24,
                                    md: 34
                                }}
                            >
                                Analyze your audience and<br />keep them engaged
                            </Typography>
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                sx={{
                                    marginTop: 2
                                }}
                            >
                                Track your engagement over time, monitor revenue and learn what’s converting your audience. Make informed updates on the fly to keep them coming back.
                            </Typography>
                            <Button
                                component={Link}
                                to="/signup"
                                variant="contained"
                                size="large"
                                disableElevation
                                sx={{
                                    marginTop: 4
                                }}
                            >
                                Get started
                            </Button>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            item
                        >
                            <Box
                                sx={{
                                    marginX: 'auto',
                                    width: '100%',
                                    maxWidth: 390
                                }}
                            >
                                <Card elevation={0}>
                                    <Box padding={3}>
                                        <Grid
                                            alignItems="center"
                                            columnSpacing={10}
                                            container
                                        >
                                            <Grid item>
                                                <Typography
                                                    variant="body1"
                                                    color="text.secondary"
                                                >
                                                    Sales
                                                </Typography>
                                                <Typography variant="h4">
                                                    347M
                                                </Typography>
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    spacing={1}
                                                    sx={{
                                                        marginTop: 1
                                                    }}
                                                >
                                                    <SvgIcon
                                                        fontSize="small"
                                                        color="primary"
                                                    >
                                                        <TrendingUp />
                                                    </SvgIcon>
                                                    <Typography variant="body1">
                                                        8%
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    variant="body1"
                                                    color="text.secondary"
                                                >
                                                    Engagement
                                                </Typography>
                                                <Typography variant="h4">
                                                    87%
                                                </Typography>
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    spacing={1}
                                                    sx={{
                                                        marginTop: 1
                                                    }}
                                                >
                                                    <SvgIcon
                                                        fontSize="small"
                                                        color="secondary"
                                                    >
                                                        <TrendingDown />
                                                    </SvgIcon>
                                                    <Typography variant="body1">
                                                        3%
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Card>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    marginY={{
                        xs: 8,
                        sm: 12,
                        md: 16,
                        lg: 20
                    }}
                >
                    <Grid container>
                        <Grid
                            xs={12}
                            md={10}
                            lg={8}
                            item
                        >
                            <Typography
                                variant="h4"
                                fontSize={{
                                    xs: 24,
                                    md: 34
                                }}
                            >
                                Become next<br /><Celebrity>{CELEBRITIES[index % CELEBRITIES.length]}</Celebrity> :)
                            </Typography>
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                sx={{
                                    marginTop: 2
                                }}
                            >
                                World's biggest influencers, content creators, publishers and brands use Paiz in their marketing strategy. Join us and get yourself noticed too.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box marginTop={4}>
                        <Grid
                            spacing={2}
                            container
                        >
                            <Grid
                                xs={2}
                                sm={1.5}
                                lg={1}
                                item
                            >
                                <CardActionArea
                                    component={Link}
                                    to="/selena"
                                    sx={{
                                        borderRadius: '50%'
                                    }}
                                >
                                    <Avatar
                                        src="/static/images/clippers.png"
                                        sx={{
                                            aspectRatio: 1,
                                            width: '100%',
                                            height: 'auto'
                                        }}
                                    />
                                </CardActionArea>
                            </Grid>
                            <Grid
                                xs={2}
                                sm={1.5}
                                lg={1}
                                item
                            >
                                <CardActionArea
                                    component={Link}
                                    to="/selena"
                                    sx={{
                                        borderRadius: '50%'
                                    }}
                                >
                                    <Avatar
                                        src="/static/images/pharrell.jpg"
                                        sx={{
                                            aspectRatio: 1,
                                            width: '100%',
                                            height: 'auto'
                                        }}
                                    />
                                </CardActionArea>
                            </Grid>
                            <Grid
                                xs={2}
                                sm={1.5}
                                lg={1}
                                item
                            >
                                <CardActionArea
                                    component={Link}
                                    to="/selena"
                                    sx={{
                                        borderRadius: '50%'
                                    }}
                                >
                                    <Avatar
                                        src="/static/images/selena.jpg"
                                        sx={{
                                            aspectRatio: 1,
                                            width: '100%',
                                            height: 'auto'
                                        }}
                                    />
                                </CardActionArea>
                            </Grid>
                            <Grid
                                xs={2}
                                sm={1.5}
                                lg={1}
                                item
                            >
                                <CardActionArea
                                    component={Link}
                                    to="/selena"
                                    sx={{
                                        borderRadius: '50%'
                                    }}
                                >
                                    <Avatar
                                        src="/static/images/funkynutmeg.jpg"
                                        sx={{
                                            aspectRatio: 1,
                                            width: '100%',
                                            height: 'auto'
                                        }}
                                    />
                                </CardActionArea>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <div id="pricing">
                    <Element name="pricing">
                        <Box
                            marginY={{
                                xs: 8,
                                sm: 12,
                                md: 16,
                                lg: 20
                            }}
                        >
                            <Typography
                                textAlign="center"
                                variant="h4"
                                fontSize={{
                                    xs: 24,
                                    md: 34
                                }}
                                sx={{
                                    marginBottom: 4
                                }}
                            >
                                Pricing plan
                            </Typography>
                            <Grid
                                justifyContent={{
                                    xs: 'flex-start',
                                    md: 'center'
                                }}
                                spacing={{
                                    xs: 1,
                                    md: 2,
                                    lg: 4
                                }}
                                container
                            >
                                <Grid
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    xl={3}
                                    item
                                >
                                    <PricingPlan
                                        name="Free"
                                        monthlyPrice={0}
                                        features={[
                                            'Customizeable Paiz',
                                            'Unlimited links',
                                            'Up to 2 Promoted links',
                                            '30% fee on Promoted links'
                                        ]}
                                    />
                                </Grid>
                                <Grid
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    xl={3}
                                    item
                                >
                                    <PricingPlan
                                        name="PRO"
                                        duration="annual"
                                        monthlyPrice={3}
                                        isRecommended
                                        features={[
                                            'Customizeable Paiz',
                                            'Unlimited links',
                                            'Unlimited Promoted links',
                                            '5% fee on Promoted links',
                                            'Advanced Analytics'
                                        ]}
                                    />
                                </Grid>
                                <Grid
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    xl={3}
                                    item
                                >
                                    <PricingPlan
                                        name="PRO"
                                        duration="monthly"
                                        monthlyPrice={5}
                                        features={[
                                            'Customizeable Paiz',
                                            'Unlimited links',
                                            'Unlimited Promoted links',
                                            '5% fee on Promoted links',
                                            'Advanced Analytics'
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Element>
                </div>
                <div id="faq">
                    <Element name="faq">
                        <Box
                            marginY={{
                                xs: 8,
                                sm: 12,
                                md: 16,
                                lg: 20
                            }}
                        >
                            <Typography
                                textAlign="center"
                                variant="h4"
                                fontSize={{
                                    xs: 24,
                                    md: 34
                                }}
                            >
                                Got questions?
                            </Typography>
                            <Grid
                                justifyContent="center"
                                container
                                sx={{
                                    marginTop: 4
                                }}
                            >
                                <Grid
                                    xs={12}
                                    md={8}
                                    lg={6}
                                    item
                                >
                                    <Stack spacing={1}>
                                        <Accordion
                                            summary="What is Paiz?"
                                            details="Paiz allows you to create a personalized and customizable page that houses all the important links that you want to share with your audience. It's a link-in-bio solution that can be used on social platforms like Instagram, TikTok, Twitch, Facebook, YouTube, Twitter, LinkedIn, and more. You should use Paiz to aid the discovery of your work, brand, or business."
                                        />
                                        <Accordion
                                            summary="Why do creators use Paiz?"
                                            details="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
                                        />
                                        <Accordion
                                            summary="Why do i need a Paiz?"
                                            details="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
                                        />
                                        <Accordion
                                            summary="Why should i use Paiz instead of any other tools?"
                                            details="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
                                        />
                                        <Accordion
                                            summary="Can i sell things from Paiz?"
                                            details="Paiz is the perfect place to start selling your products & services, generate affiliate revenue and get paid for the things you create, curate and care about."
                                        />
                                        <Accordion
                                            summary="How many links can i have on Paiz?"
                                            details="Even with a free plan, Paiz offers unlimited links."
                                        />
                                        <Accordion
                                            summary="How can I drive more traffic to and through my Paiz?"
                                            details="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Element>
                </div>
            </Container>
        </div>
    );
});