import type { } from '@mui/lab/themeAugmentation';
import { createTheme } from '@mui/material/styles';

export const light = createTheme({
    palette: {
        primary: {
            main: '#918cf2'
        },
        secondary: {
            main: '#ec91d8'
        },
        success: {
            main: '#61d1ac'
        },
        warning: {
            main: '#ecf589'
        },
        background: {
            default: '#ffffff',
            paper: '#f6f7f9'
        },
        mode: 'light'
    },
    shape: {
        borderRadius: 8
    },
    typography: {
        h1: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h2: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h3: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h4: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h5: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h6: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        subtitle1: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        subtitle2: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        body1: {
            fontFamily: 'Nunito',
            fontWeight: 400
        },
        body2: {
            fontFamily: 'Nunito',
            fontWeight: 400
        },
        button: {
            fontFamily: 'Nunito',
            fontWeight: 700,
            textTransform: 'none'
        },
        caption: {
            fontFamily: 'Nunito',
            fontWeight: 400
        },
        overline: {
            fontFamily: 'Nunito',
            fontWeight: 400
        }
    },
    components: {
        MuiUseMediaQuery: {
            defaultProps: {
                noSsr: true
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: '4px',
                    fontFamily: 'Nunito',
                    fontWeight: 700
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                InputProps: {
                    sx: {
                        backgroundColor: '#f6f7f9'
                    }
                }
            }
        }
    }
});