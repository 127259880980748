import { ApolloError, gql, MutationFunction, MutationResult, QueryResult } from '@apollo/client';
import { Mutation, Query } from '@apollo/client/react/components';
import { Box, Button, Container, Dialog, DialogContent, DialogTitle, Grid, Menu, MenuItem, Stack, SvgIcon, Typography } from '@mui/material';
import { arrayMoveImmutable } from 'array-move';
import { Fragment, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Plus } from 'react-feather';
import { Helmet } from 'react-helmet';
import { Addon } from '../components/Addon';
import { AddonSkeleton } from '../components/AddonSkeleton';
import { createUseState } from '../components/common/UseState';
import { LinkForm } from '../forms/Link';
import { VCardForm } from '../forms/VCard';
import { YoutubeForm } from '../forms/Youtube';
import { AddonFields } from '../fragments/addon-fields';
import { parseErrors } from '../lib/helpers';
import { ValidationError } from '../types';

const UseValidationErrorsState = createUseState<ValidationError[]>();

const CURRENT_USER_ADDONS = gql`
    ${AddonFields}
    query CurrentUserAddons {
        currentUserAddons(
            sortBy: "order"
            sortOrder: "asc"
        ) {
            edges {
                ...AddonFields
            }
        }
    }
`;

const CREATE_ADDON = gql`
    ${AddonFields}
    mutation CreateAddon(
        $type: String
        $title: String
        $url: String
        $order: Int
        $firstName: String
        $lastName: String
        $organization: String
        $position: String
        $emailPrimary: String
        $emailPrimaryType: String
        $emailSecondary: String
        $emailSecondaryType: String
        $phonePrimary: String
        $phonePrimaryType: String
        $phoneSecondary: String
        $phoneSecondaryType: String
        $addressLine1: String
        $addressLine2: String
        $addressCity: String
        $addressCountry: String
        $addressState: String
        $addressPostcode: String
    ) {
        createAddon(input: {
            type: $type
            title: $title
            url: $url
            order: $order
            firstName:$firstName
            lastName:$lastName
            organization:$organization
            position:$position
            emailPrimary:$emailPrimary
            emailPrimaryType:$emailPrimaryType
            emailSecondary:$emailSecondary
            emailSecondaryType:$emailSecondaryType
            phonePrimary:$phonePrimary
            phonePrimaryType:$phonePrimaryType
            phoneSecondary:$phoneSecondary
            phoneSecondaryType:$phoneSecondaryType
            addressLine1:$addressLine1
            addressLine2:$addressLine2
            addressCity:$addressCity
            addressCountry:$addressCountry
            addressState:$addressState
            addressPostcode:$addressPostcode
        }) {
            ...AddonFields
        }
    }
`;

const UPDATE_ADDON_ORDERS = gql`
    mutation UpdateAddonOrders(
        $addons: [AddonOrderDto!]
    ) {
        updateAddonOrders(input: {
            addons: $addons
        })
    }
`;

export type AddonType = {
    id: string;
    type: string;
    title: string;
    url?: string;
    thumbnail?: string;
    order: number;
    visible: boolean;
    vCard: {
        firstName?: string;
        lastName?: string;
        organization?: string;
        position?: string;
        emailPrimary?: string;
        emailPrimaryType?: string;
        emailSecondary?: string;
        emailSecondaryType?: string;
        phonePrimary?: string;
        phonePrimaryType?: string;
        phoneSecondary?: string;
        phoneSecondaryType?: string;
        addressLine1?: string;
        addressLine2?: string;
        addressCity?: string;
        addressCountry?: string;
        addressState?: string;
        addressPostcode?: string;
    };
};

export const Links = (): JSX.Element => {
    const [
        anchorEl,
        setAnchorEl
    ] = useState<HTMLElement | null>(null);
    const [
        form,
        setForm
    ] = useState<string | null>(null);
    const [
        order,
        setOrder
    ] = useState(0);
    const [
        addons,
        setAddons
    ] = useState<AddonType[]>([]);

    useEffect(() => {
        setOrder(addons.length ? addons[addons.length - 1].order + 1 : 0);
    }, [addons]);

    const onDragEnd = (mutate: MutationFunction) => ({ destination, source }: DropResult) => {
        if (!destination) {
            return;
        }
        const data = arrayMoveImmutable(addons, source.index, destination.index).map((addon, order) => ({ ...addon, order }));
        setAddons(data);
        const variables = {
            addons: data.map(({ id, order }) => ({ id, order }))
        };
        mutate({
            variables
        });
    };

    return (
        <Fragment>
            <Helmet>
                <title>
                    Links - Paiz.io
                </title>
            </Helmet>
            <Container maxWidth="sm">
                <Box
                    margin={{
                        xs: '32px 0 16px',
                        sm: '32px 0 24px'
                    }}
                >
                    <Typography
                        variant="h4"
                        textAlign="center"
                        fontSize={{
                            xs: 24,
                            md: 34
                        }}
                    >
                        Links
                    </Typography>
                    <Query
                        fetchPolicy="no-cache"
                        query={CURRENT_USER_ADDONS}
                        variables={{

                        }}
                        onError={(err: ApolloError) => {
                            //
                        }}
                        onCompleted={(data: any) => {
                            setAddons(data.currentUserAddons.edges);
                        }}
                    >
                        {({ loading }: QueryResult) => (
                            <Fragment>
                                {loading && (
                                    <Stack
                                        spacing={2}
                                        sx={{
                                            marginTop: 4
                                        }}
                                    >
                                        <AddonSkeleton />
                                        <AddonSkeleton />
                                        <AddonSkeleton />
                                    </Stack>
                                )}
                                {loading === false && addons.length === 0 && (
                                    <Stack
                                        alignItems="center"
                                        spacing={2}
                                        sx={{
                                            marginTop: 4
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src="/static/images/empty-box.png"
                                            sx={{
                                                display: 'inline-block',
                                                width: 96,
                                                height: 'auto'
                                            }}
                                        />
                                        <Typography
                                            variant="body1"
                                            color="text.disabled"
                                            textAlign="center"
                                        >
                                            You have no links. Please add a new link.
                                        </Typography>
                                    </Stack>
                                )}
                                <Mutation mutation={UPDATE_ADDON_ORDERS}>
                                    {(mutate: MutationFunction) => (
                                        <DragDropContext onDragEnd={onDragEnd(mutate)}>
                                            <Droppable droppableId="droppable-addons">
                                                {provided => (
                                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                                        <Grid
                                                            rowSpacing={2}
                                                            container
                                                            sx={{
                                                                marginTop: 2
                                                            }}
                                                        >
                                                            {addons.map(({ id, type, title, url, thumbnail, order, visible, vCard }) => (
                                                                <Draggable
                                                                    key={id}
                                                                    index={order}
                                                                    draggableId={id}
                                                                >
                                                                    {provided => (
                                                                        <Grid
                                                                            {...provided.draggableProps}
                                                                            ref={provided.innerRef}
                                                                            xs={12}
                                                                            item
                                                                        >
                                                                            <Addon
                                                                                dragHandleProps={provided.dragHandleProps}
                                                                                id={id}
                                                                                type={type}
                                                                                title={title}
                                                                                url={url}
                                                                                thumbnail={thumbnail}
                                                                                visible={visible}
                                                                                vCard={vCard}
                                                                                onUpdate={newAddon => {
                                                                                    setAddons(addons.map(addon => addon.id !== newAddon.id ? addon : newAddon));
                                                                                }}
                                                                                onDelete={() => {
                                                                                    setAddons(addons.filter(addon => addon.id !== id));
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </Grid>
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    )}
                                </Mutation>
                            </Fragment>
                        )}
                    </Query>
                    <Stack
                        alignItems="center"
                        sx={{
                            marginTop: 4
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            startIcon={
                                <SvgIcon>
                                    <Plus />
                                </SvgIcon>
                            }
                            onClick={e => {
                                setAnchorEl(e.currentTarget);
                            }}
                        >
                            Add link
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => {
                                setAnchorEl(null);
                            }}
                            PaperProps={{
                                sx: {
                                    minWidth: 240
                                }
                            }}
                            anchorOrigin={{
                                horizontal: 'left',
                                vertical: 'top'
                            }}
                            transformOrigin={{
                                horizontal: 'left',
                                vertical: 'top'
                            }}
                        >
                            <MenuItem
                                onClick={() => {
                                    setForm('link');
                                    setAnchorEl(null);
                                }}
                            >
                                Link
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setForm('v-card');
                                    setAnchorEl(null);
                                }}
                            >
                                Contact details
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setForm('youtube');
                                    setAnchorEl(null);
                                }}
                            >
                                Youtube
                            </MenuItem>
                        </Menu>
                        <Dialog
                            open={form === 'link'}
                            onClose={(_, reason) => {
                                reason === 'escapeKeyDown' && setForm(null);
                            }}
                            maxWidth="xs"
                            fullWidth
                        >
                            <DialogTitle>
                                Add link
                            </DialogTitle>
                            <DialogContent>
                                <UseValidationErrorsState defaultValue={[]}>
                                    {(errors, setErrors) => (
                                        <Mutation
                                            mutation={CREATE_ADDON}
                                            variables={{
                                                order,
                                                type: 'link'
                                            }}
                                            onError={(err: ApolloError) => {
                                                if (err.message === 'Bad Request Exception') {
                                                    setErrors(parseErrors(err));
                                                }
                                            }}
                                            onCompleted={(data: { createAddon: AddonType; }) => {
                                                setAddons([...addons, data.createAddon]);
                                                setForm(null);
                                            }}
                                        >
                                            {(mutate: MutationFunction, { loading }: MutationResult) => (
                                                <LinkForm
                                                    errors={errors}
                                                    initialValues={{
                                                        title: '',
                                                        url: ''
                                                    }}
                                                    submitButtonProps={{
                                                        loading
                                                    }}
                                                    onSubmit={values => {
                                                        setErrors([]);
                                                        mutate({
                                                            variables: values
                                                        });
                                                    }}
                                                    onCancel={() => {
                                                        setForm(null);
                                                    }}
                                                />
                                            )}
                                        </Mutation>
                                    )}
                                </UseValidationErrorsState>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            open={form === 'v-card'}
                            onClose={(_, reason) => {
                                reason === 'escapeKeyDown' && setForm(null);
                            }}
                            maxWidth="sm"
                            scroll="body"
                            fullWidth
                        >
                            <DialogTitle>
                                Add contact details
                            </DialogTitle>
                            <DialogContent>
                                <UseValidationErrorsState defaultValue={[]}>
                                    {(errors, setErrors) => (
                                        <Mutation
                                            mutation={CREATE_ADDON}
                                            variables={{
                                                order,
                                                type: 'v-card'
                                            }}
                                            onError={(err: ApolloError) => {
                                                if (err.message === 'Bad Request Exception') {
                                                    setErrors(parseErrors(err));
                                                }
                                            }}
                                            onCompleted={(data: { createAddon: AddonType; }) => {
                                                setAddons([...addons, data.createAddon]);
                                                setForm(null);
                                            }}
                                        >
                                            {(mutate: MutationFunction, { loading }: MutationResult) => (
                                                <VCardForm
                                                    errors={errors}
                                                    initialValues={{
                                                        title: '',
                                                        firstName: '',
                                                        lastName: '',
                                                        organization: '',
                                                        position: '',
                                                        emailPrimary: '',
                                                        emailPrimaryType: '',
                                                        emailSecondary: '',
                                                        emailSecondaryType: '',
                                                        phonePrimary: '',
                                                        phonePrimaryType: '',
                                                        phoneSecondary: '',
                                                        phoneSecondaryType: '',
                                                        addressLine1: '',
                                                        addressLine2: '',
                                                        addressCity: '',
                                                        addressCountry: '',
                                                        addressState: '',
                                                        addressPostcode: ''
                                                    }}
                                                    submitButtonProps={{
                                                        loading
                                                    }}
                                                    onSubmit={values => {
                                                        setErrors([]);
                                                        mutate({
                                                            variables: values
                                                        });
                                                    }}
                                                    onCancel={() => {
                                                        setForm(null);
                                                    }}
                                                />
                                            )}
                                        </Mutation>
                                    )}
                                </UseValidationErrorsState>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            open={form === 'youtube'}
                            onClose={(_, reason) => {
                                reason === 'escapeKeyDown' && setForm(null);
                            }}
                            maxWidth="xs"
                            fullWidth
                        >
                            <DialogTitle>
                                Add youtube
                            </DialogTitle>
                            <DialogContent>
                                <UseValidationErrorsState defaultValue={[]}>
                                    {(errors, setErrors) => (
                                        <Mutation
                                            mutation={CREATE_ADDON}
                                            variables={{
                                                order,
                                                type: 'youtube'
                                            }}
                                            onError={(err: ApolloError) => {
                                                if (err.message === 'Bad Request Exception') {
                                                    setErrors(parseErrors(err));
                                                }
                                            }}
                                            onCompleted={(data: { createAddon: AddonType; }) => {
                                                setAddons([...addons, data.createAddon]);
                                                setForm(null);
                                            }}
                                        >
                                            {(mutate: MutationFunction, { loading }: MutationResult) => (
                                                <YoutubeForm
                                                    errors={errors}
                                                    initialValues={{
                                                        title: '',
                                                        url: ''
                                                    }}
                                                    submitButtonProps={{
                                                        loading
                                                    }}
                                                    onSubmit={values => {
                                                        setErrors([]);
                                                        mutate({
                                                            variables: values
                                                        });
                                                    }}
                                                    onCancel={() => {
                                                        setForm(null);
                                                    }}
                                                />
                                            )}
                                        </Mutation>
                                    )}
                                </UseValidationErrorsState>
                            </DialogContent>
                        </Dialog>
                    </Stack>
                </Box>
            </Container>
        </Fragment>
    );
};