import { ApolloError, gql, MutationFunction, MutationResult } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { Box, Card, Chip, Collapse, Divider, Grid, IconButton, Stack, SvgIcon, Switch, Typography } from '@mui/material';
import { Fragment } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { Edit, Menu, Trash } from 'react-feather';
import { connect } from 'react-redux';
import { LinkForm } from '../forms/Link';
import { VCardForm } from '../forms/VCard';
import { YoutubeForm } from '../forms/Youtube';
import { AddonFields } from '../fragments/addon-fields';
import { parseErrors } from '../lib/helpers';
import { AddonType } from '../pages/Links';
import { RootState } from '../redux/configureStore';
import { ValidationError } from '../types';
import { Confirm } from './common/Confirm';
import { createUseState } from './common/UseState';

const UseBooleanState = createUseState<boolean>();
const UseValidationErrorsState = createUseState<ValidationError[]>();

const UPDATE_ADDON = gql`
    ${AddonFields}
    mutation UpdateAddon(
        $id: String!
        $type: String
        $title: String
        $url: String
        $thumbnail: String
        $visible: Boolean
        $firstName: String
        $lastName: String
        $organization: String
        $position: String
        $emailPrimary: String
        $emailPrimaryType: String
        $emailSecondary: String
        $emailSecondaryType: String
        $phonePrimary: String
        $phonePrimaryType: String
        $phoneSecondary: String
        $phoneSecondaryType: String
        $addressLine1: String
        $addressLine2: String
        $addressCity: String
        $addressCountry: String
        $addressState: String
        $addressPostcode: String
    ) {
        updateAddon(input: {
            id: $id
            type: $type
            title: $title
            url: $url
            thumbnail: $thumbnail
            visible: $visible
            firstName: $firstName
            lastName: $lastName
            organization: $organization
            position: $position
            emailPrimary: $emailPrimary
            emailPrimaryType: $emailPrimaryType
            emailSecondary: $emailSecondary
            emailSecondaryType: $emailSecondaryType
            phonePrimary: $phonePrimary
            phonePrimaryType: $phonePrimaryType
            phoneSecondary: $phoneSecondary
            phoneSecondaryType: $phoneSecondaryType
            addressLine1: $addressLine1
            addressLine2: $addressLine2
            addressCity: $addressCity
            addressCountry: $addressCountry
            addressState: $addressState
            addressPostcode: $addressPostcode
        }) {
            ...AddonFields
        }
    }
`;

const UPDATE_ADDON_VISIBILITY = gql`
    ${AddonFields}
    mutation UpdateAddonVisibility(
        $id: String!
        $visible: Boolean
    ) {
        updateAddonVisibility(input: {
            id: $id
            visible: $visible
        }) {
            ...AddonFields
        }
    }
`;

const DELETE_ADDON = gql`
    mutation DeleteAddon($id: String!) {
        deleteAddon(id: $id) {
            id
        }
    }
`;

type AddonProps = {
    user: any;
    dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
    id: string;
    type: string;
    title: string;
    url?: string;
    thumbnail?: string;
    visible: boolean;
    vCard: {
        firstName?: string;
        lastName?: string;
        organization?: string;
        position?: string;
        emailPrimary?: string;
        emailPrimaryType?: string;
        emailSecondary?: string;
        emailSecondaryType?: string;
        phonePrimary?: string;
        phonePrimaryType?: string;
        phoneSecondary?: string;
        phoneSecondaryType?: string;
        addressLine1?: string;
        addressLine2?: string;
        addressCity?: string;
        addressCountry?: string;
        addressState?: string;
        addressPostcode?: string;
    };
    onUpdate: (addon: AddonType) => void;
    onDelete: () => void;
};

const mapStateToProps = (state: RootState) => ({
    user: state.auth.user
});

export const Addon = connect(mapStateToProps)((props: AddonProps): JSX.Element => {
    const {
        user,
        dragHandleProps,
        id,
        type,
        title,
        url,
        thumbnail,
        visible,
        vCard,
        onUpdate,
        onDelete
    } = props;

    const {
        firstName,
        lastName,
        organization,
        position,
        emailPrimary,
        emailPrimaryType,
        emailSecondary,
        emailSecondaryType,
        phonePrimary,
        phonePrimaryType,
        phoneSecondary,
        phoneSecondaryType,
        addressLine1,
        addressLine2,
        addressCity,
        addressCountry,
        addressState,
        addressPostcode
    } = vCard || {} as AddonType['vCard'];

    return (
        <UseBooleanState defaultValue={false}>
            {(open, setOpen) => (
                <Card elevation={0}>
                    <Box padding={2}>
                        <Grid
                            justifyContent="space-between"
                            alignItems="center"
                            wrap="nowrap"
                            columnSpacing={2}
                            container
                        >
                            <Grid item>
                                <IconButton
                                    {...dragHandleProps}
                                    size="small"
                                    disableRipple
                                >
                                    <SvgIcon htmlColor="#6a6c6f">
                                        <Menu />
                                    </SvgIcon>
                                </IconButton>
                            </Grid>
                            <Grid
                                item
                                xs
                                sx={{
                                    overflow: 'hidden'
                                }}
                            >
                                <Typography
                                    variant="subtitle1"
                                    noWrap
                                >
                                    {title}
                                </Typography>
                                {url && (
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        noWrap
                                    >
                                        {url}
                                    </Typography>
                                )}
                                {type === 'v-card' && (
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        noWrap
                                    >
                                        https://paiz.io/{user.username}
                                    </Typography>
                                )}
                                {type === 'link' && (
                                    <Chip
                                        label="link"
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        sx={{
                                            marginTop: 1
                                        }}
                                    />
                                )}
                                {type === 'v-card' && (
                                    <Chip
                                        label="contact details"
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        sx={{
                                            marginTop: 1
                                        }}
                                    />
                                )}
                                {type === 'youtube' && (
                                    <Chip
                                        label="youtube"
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        sx={{
                                            marginTop: 1
                                        }}
                                    />
                                )}
                            </Grid>
                            <Grid item>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            setOpen(open ? false : true);
                                        }}
                                    >
                                        <SvgIcon htmlColor="#6a6c6f">
                                            <Edit />
                                        </SvgIcon>
                                    </IconButton>
                                    <UseBooleanState defaultValue={false}>
                                        {(open, setOpen) => (
                                            <Mutation
                                                mutation={DELETE_ADDON}
                                                variables={{
                                                    id
                                                }}
                                                onError={(err: ApolloError) => {
                                                    //
                                                }}
                                                onCompleted={() => {
                                                    onDelete();
                                                    setOpen(false);
                                                }}
                                            >
                                                {(mutate: MutationFunction, { loading }: MutationResult) => (
                                                    <Fragment>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => {
                                                                setOpen(true);
                                                            }}
                                                        >
                                                            <SvgIcon htmlColor="#6a6c6f">
                                                                <Trash />
                                                            </SvgIcon>
                                                        </IconButton>
                                                        <Confirm
                                                            title="Delete"
                                                            description="Are you sure you want to delete this item?"
                                                            okText="Yes, delete it"
                                                            cancelText="Cancel"
                                                            open={open}
                                                            onOk={mutate}
                                                            onClose={() => {
                                                                setOpen(false);
                                                            }}
                                                            onCancel={() => {
                                                                setOpen(false);
                                                            }}
                                                            okButtonProps={{
                                                                color: 'error',
                                                                loading
                                                            }}
                                                        />
                                                    </Fragment>
                                                )}
                                            </Mutation>
                                        )}
                                    </UseBooleanState>
                                    <Mutation
                                        mutation={UPDATE_ADDON_VISIBILITY}
                                        variables={{
                                            id,
                                            visible
                                        }}
                                        onError={(err: ApolloError) => {
                                            //
                                        }}
                                        onCompleted={(data: { updateAddonVisibility: AddonType; }) => {
                                            onUpdate(data.updateAddonVisibility);
                                        }}
                                    >
                                        {(mutate: MutationFunction, { loading }: MutationResult) => (
                                            <Switch
                                                color="primary"
                                                disabled={loading}
                                                defaultChecked={visible}
                                                onChange={e => {
                                                    const variables = {
                                                        visible: e.target.checked
                                                    };
                                                    mutate({
                                                        variables
                                                    });
                                                }}
                                            />
                                        )}
                                    </Mutation>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse in={open}>
                        <Divider />
                        <Box padding={2}>
                            {type === 'link' && (
                                <UseValidationErrorsState defaultValue={[]}>
                                    {(errors, setErrors) => (
                                        <Mutation
                                            mutation={UPDATE_ADDON}
                                            variables={{
                                                id,
                                                type,
                                                visible
                                            }}
                                            onError={(err: ApolloError) => {
                                                if (err.message === 'Bad Request Exception') {
                                                    setErrors(parseErrors(err));
                                                }
                                            }}
                                            onCompleted={(data: { updateAddon: AddonType; }) => {
                                                onUpdate(data.updateAddon);
                                                setOpen(false);
                                            }}
                                        >
                                            {(mutate: MutationFunction, { loading }: MutationResult) => (
                                                <LinkForm
                                                    errors={errors}
                                                    initialValues={{
                                                        title,
                                                        url,
                                                        // thumbnail
                                                    }}
                                                    submitButtonProps={{
                                                        loading
                                                    }}
                                                    onSubmit={values => {
                                                        setErrors([]);
                                                        mutate({
                                                            variables: values
                                                        });
                                                    }}
                                                    onCancel={() => {
                                                        setOpen(false);
                                                    }}
                                                />
                                            )}
                                        </Mutation>
                                    )}
                                </UseValidationErrorsState>
                            )}
                            {type === 'v-card' && (
                                <UseValidationErrorsState defaultValue={[]}>
                                    {(errors, setErrors) => (
                                        <Mutation
                                            mutation={UPDATE_ADDON}
                                            variables={{
                                                id,
                                                type,
                                                visible
                                            }}
                                            onError={(err: ApolloError) => {
                                                if (err.message === 'Bad Request Exception') {
                                                    setErrors(parseErrors(err));
                                                }
                                            }}
                                            onCompleted={(data: { updateAddon: AddonType; }) => {
                                                onUpdate(data.updateAddon);
                                                setOpen(false);
                                            }}
                                        >
                                            {(mutate: MutationFunction, { loading }: MutationResult) => (
                                                <VCardForm
                                                    errors={errors}
                                                    initialValues={{
                                                        title,
                                                        // thumbnail,
                                                        firstName,
                                                        lastName,
                                                        organization,
                                                        position,
                                                        emailPrimary,
                                                        emailPrimaryType,
                                                        emailSecondary,
                                                        emailSecondaryType,
                                                        phonePrimary,
                                                        phonePrimaryType,
                                                        phoneSecondary,
                                                        phoneSecondaryType,
                                                        addressLine1,
                                                        addressLine2,
                                                        addressCity,
                                                        addressCountry,
                                                        addressState,
                                                        addressPostcode
                                                    }}
                                                    submitButtonProps={{
                                                        loading
                                                    }}
                                                    onSubmit={values => {
                                                        setErrors([]);
                                                        mutate({
                                                            variables: values
                                                        });
                                                    }}
                                                    onCancel={() => {
                                                        setOpen(false);
                                                    }}
                                                />
                                            )}
                                        </Mutation>
                                    )}
                                </UseValidationErrorsState>
                            )}
                            {type === 'youtube' && (
                                <UseValidationErrorsState defaultValue={[]}>
                                    {(errors, setErrors) => (
                                        <Mutation
                                            mutation={UPDATE_ADDON}
                                            variables={{
                                                id,
                                                type,
                                                visible
                                            }}
                                            onError={(err: ApolloError) => {
                                                if (err.message === 'Bad Request Exception') {
                                                    setErrors(parseErrors(err));
                                                }
                                            }}
                                            onCompleted={(data: { updateAddon: AddonType; }) => {
                                                onUpdate(data.updateAddon);
                                                setOpen(false);
                                            }}
                                        >
                                            {(mutate: MutationFunction, { loading }: MutationResult) => (
                                                <YoutubeForm
                                                    errors={errors}
                                                    initialValues={{
                                                        title,
                                                        url,
                                                        // thumbnail
                                                    }}
                                                    submitButtonProps={{
                                                        loading
                                                    }}
                                                    onSubmit={values => {
                                                        setErrors([]);
                                                        mutate({
                                                            variables: values
                                                        });
                                                    }}
                                                    onCancel={() => {
                                                        setOpen(false);
                                                    }}
                                                />
                                            )}
                                        </Mutation>
                                    )}
                                </UseValidationErrorsState>
                            )}
                        </Box>
                    </Collapse>
                </Card>
            )}
        </UseBooleanState>
    );
});