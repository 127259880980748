import { LoadingButton } from '@mui/lab';
import { Button, Stack, TextField } from '@mui/material';
import { Formik } from 'formik';
import { getValidationErrorMessage } from '../lib/helpers';
import { ValidationError } from '../types';

type SubmitButtonProps = {
    loading: boolean;
};

type Values = {
    title: string;
    url?: string;
};

type LinkFormProps = {
    onSubmit: (values: Values) => Promise<void> | void;
    onCancel: () => void;
    initialValues: Values;
    errors: ValidationError[];
    submitButtonProps?: SubmitButtonProps;
};

export const LinkForm = (props: LinkFormProps): JSX.Element => {
    const {
        submitButtonProps = {},
        onCancel,
        errors,
        ...otherProps
    } = props;

    return (
        <Formik {...otherProps}>
            {({ values, handleChange, handleSubmit, getFieldHelpers }) => (
                <form
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    noValidate
                >
                    <TextField
                        label="Title"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        error={errors.some(({ property }) => property === 'title')}
                        helperText={getValidationErrorMessage(errors, 'title')}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        required
                    />
                    <TextField
                        label="URL"
                        name="url"
                        value={values.url}
                        onChange={handleChange}
                        error={errors.some(({ property }) => property === 'url')}
                        helperText={getValidationErrorMessage(errors, 'url')}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        required
                    />
                    <Stack
                        justifyContent="flex-end"
                        alignItems="center"
                        direction="row"
                        spacing={1}
                        sx={{
                            marginTop: 2
                        }}
                    >
                        <LoadingButton
                            {...submitButtonProps}
                            variant="contained"
                            color="primary"
                            type="submit"
                            disableElevation
                        >
                            Save
                        </LoadingButton>
                        <Button
                            variant="outlined"
                            color="inherit"
                            disableElevation
                            onClick={onCancel}
                            sx={{
                                border: theme => `1px solid ${theme.palette.divider}`
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </form>
            )}
        </Formik>
    );
};