import { ApolloError, gql, MutationFunction, MutationResult } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { LoadingButton } from '@mui/lab';
import { Alert, Avatar, Box, Button, Card, Container, Divider, Grid, Link as MuiLink, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import { Fragment, useRef } from 'react';
import { User } from 'react-feather';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Confirm } from '../components/common/Confirm';
import { createUseState } from '../components/common/UseState';
import { useUpload } from '../hooks/useUpload';
import { setAccessToken, setUser } from '../redux/auth/actions';
import { RootState } from '../redux/configureStore';

const UseBooleanState = createUseState<boolean>();

const SEND_PASSWORD_RESET_LINK = gql`
    mutation SendPasswordResetLink($email: String) {
        sendPasswordResetLink(input: {
            email: $email
            callbackUrl: "https://paiz.io"
        })
    }
`;

const SEND_VERIFICATION_EMAIL = gql`
    mutation SendVerificationEmail {
        sendVerificationEmail
    }
`;

const UPDATE_CURRENT_USER = gql`
    mutation UpdateCurrentUser(
        $bio: String
        $name: String
        $profileImage: String
        $contentWarning: String
    ) {
        updateCurrentUser(input: {
            bio: $bio
            name: $name
            profileImage: $profileImage
            contentWarning: $contentWarning
        }) {
            id
            name
            username
            profileImage
            bio
            contentWarning
            theme
            email
            emailVerified
        }
    }
`;

const DELETE_CURRENT_USER = gql`
    mutation DeleteCurrentUser {
        deleteCurrentUser {
            id
        }
    }
`;

type SettingsProps = {
    setAccessToken: (accessToken: string | null) => void;
    setUser: (user: any) => void;
    user: any;
};

const mapStateToProps = (state: RootState) => ({
    user: state.auth.user
});

const mapDispatchToProps = {
    setAccessToken,
    setUser
};

export const Settings = connect(mapStateToProps, mapDispatchToProps)((props: SettingsProps): JSX.Element => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const { enqueueSnackbar } = useSnackbar();
    const {
        setAccessToken,
        setUser,
        user
    } = props;

    const [
        upload,
        uploadResult
    ] = useUpload({
        url: '/files/upload-image',
        onError: err => {
            //
        }
    });

    return (
        <Fragment>
            <Helmet>
                <title>
                    Settings - Paiz.io
                </title>
            </Helmet>
            <Container maxWidth="sm">
                <Box
                    margin={{
                        xs: '32px 0 16px',
                        sm: '32px 0 24px'
                    }}
                >
                    <Typography
                        variant="h4"
                        textAlign="center"
                        fontSize={{
                            xs: 24,
                            md: 34
                        }}
                    >
                        Settings
                    </Typography>
                    <Stack
                        spacing={4}
                        sx={{
                            marginTop: 4
                        }}
                    >
                        <Card elevation={0}>
                            <Box padding={2}>
                                <Mutation
                                    mutation={UPDATE_CURRENT_USER}
                                    onError={(err: ApolloError) => {

                                    }}
                                    onCompleted={(data: any) => {
                                        setUser(data.updateCurrentUser);
                                    }}
                                >
                                    {(mutate: MutationFunction, { loading }: MutationResult) => (
                                        <Stack
                                            alignItems="center"
                                            direction="row"
                                            spacing={2}
                                        >
                                            {user.profileImage
                                                ?
                                                <Avatar
                                                    src={`${process.env.REACT_APP_CDN_URL}/fit-in/320x320/${user.profileImage}`}
                                                    sx={{
                                                        width: 96,
                                                        height: 96
                                                    }}
                                                />
                                                :
                                                <Avatar
                                                    sx={{
                                                        width: 96,
                                                        height: 96,
                                                        backgroundColor: 'background.default',
                                                        color: 'text.disabled'
                                                    }}
                                                >
                                                    <User size={34} />
                                                </Avatar>
                                            }
                                            <Stack
                                                alignItems="center"
                                                direction="row"
                                                spacing={1}
                                            >
                                                <label>
                                                    <input
                                                        type="file"
                                                        ref={inputRef}
                                                        disabled={uploadResult.uploading}
                                                        onChange={async e => {
                                                            if (e.target.files === null) {
                                                                return;
                                                            }
                                                            const variables = {
                                                                profileImage: await upload(e.target.files[0])
                                                            };
                                                            mutate({
                                                                variables
                                                            });
                                                        }}
                                                        style={{
                                                            display: 'none'
                                                        }}
                                                    />
                                                    <LoadingButton
                                                        disabled={loading}
                                                        loading={uploadResult.uploading}
                                                        variant="outlined"
                                                        color="primary"
                                                        disableElevation
                                                        onClick={() => {
                                                            if (inputRef.current) {
                                                                inputRef.current.value = '';
                                                                inputRef.current.click();
                                                            }
                                                        }}
                                                    >
                                                        Change
                                                    </LoadingButton>
                                                </label>
                                                <Button
                                                    variant="outlined"
                                                    color="inherit"
                                                    disabled={loading}
                                                    disableElevation
                                                    onClick={() => {
                                                        const variables = {
                                                            profileImage: null
                                                        };
                                                        mutate({
                                                            variables
                                                        });
                                                    }}
                                                    sx={{
                                                        border: theme => `1px solid ${theme.palette.divider}`
                                                    }}
                                                >
                                                    Remove
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    )}
                                </Mutation>
                                <Stack
                                    spacing={1}
                                    sx={{
                                        marginTop: 4
                                    }}
                                >
                                    <Mutation
                                        mutation={UPDATE_CURRENT_USER}
                                        onError={(err: ApolloError) => {

                                        }}
                                        onCompleted={(data: any) => {
                                            setUser(data.updateCurrentUser);
                                        }}
                                    >
                                        {(mutate: MutationFunction) => (
                                            <TextField
                                                label="Full name"
                                                variant="outlined"
                                                defaultValue={user.name}
                                                onChange={debounce(e => {
                                                    const variables = {
                                                        name: e.target.value
                                                    };
                                                    mutate({
                                                        variables
                                                    });
                                                }, 500)}
                                                fullWidth
                                            />
                                        )}
                                    </Mutation>
                                    <Mutation
                                        mutation={UPDATE_CURRENT_USER}
                                        onError={(err: ApolloError) => {

                                        }}
                                        onCompleted={(data: any) => {
                                            setUser(data.updateCurrentUser);
                                        }}
                                    >
                                        {(mutate: MutationFunction) => (
                                            <TextField
                                                label="Bio"
                                                variant="outlined"
                                                defaultValue={user.bio}
                                                onChange={debounce(e => {
                                                    const variables = {
                                                        bio: e.target.value
                                                    };
                                                    mutate({
                                                        variables
                                                    });
                                                }, 500)}
                                                inputProps={{
                                                    maxLength: 160
                                                }}
                                                rows={3}
                                                multiline
                                                fullWidth
                                            />
                                        )}
                                    </Mutation>
                                </Stack>
                            </Box>
                        </Card>
                        <Card elevation={0}>
                            <Box padding={2}>
                                <Stack
                                    justifyContent="space-between"
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <div>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            Username
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {user.username}
                                        </Typography>
                                    </div>
                                    <Button
                                        component={Link}
                                        to="/edit/username"
                                        variant="outlined"
                                        color="primary"
                                        disableElevation
                                    >
                                        Edit
                                    </Button>
                                </Stack>
                            </Box>
                            <Divider />
                            <Box padding={2}>
                                <Stack
                                    justifyContent="space-between"
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <div>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            Email address
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {user.email}
                                        </Typography>
                                    </div>
                                    <Button
                                        component={Link}
                                        to="/edit/email"
                                        variant="outlined"
                                        color="primary"
                                        disableElevation
                                    >
                                        Edit
                                    </Button>
                                </Stack>
                                {user.emailVerified === false && (
                                    <Mutation
                                        mutation={SEND_VERIFICATION_EMAIL}
                                        onError={(err: ApolloError) => {

                                        }}
                                        onCompleted={(data: any) => {
                                            enqueueSnackbar('Done');
                                        }}
                                    >
                                        {(mutate: MutationFunction, { loading }: MutationResult) => (
                                            <Alert
                                                severity="error"
                                                sx={{
                                                    marginTop: 2
                                                }}
                                            >
                                                To publish your profile please verify your account by clicking the link we’ve sent to your email. <MuiLink disabled={loading} onClick={() => mutate()} component="button" variant="body2" underline="none">Resend a verification link.</MuiLink>
                                            </Alert>
                                        )}
                                    </Mutation>
                                )}
                            </Box>
                        </Card>
                        <Card elevation={0}>
                            <Box padding={2}>
                                <UseBooleanState defaultValue={false}>
                                    {(open, setOpen) => (
                                        <Mutation
                                            mutation={SEND_PASSWORD_RESET_LINK}
                                            variables={{
                                                email: user.email
                                            }}
                                            onError={(err: ApolloError) => {
                                                //
                                            }}
                                            onCompleted={() => {
                                                setOpen(false);
                                                enqueueSnackbar('Done');
                                            }}
                                        >
                                            {(mutate: MutationFunction, { loading }: MutationResult) => (
                                                <Fragment>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        disableElevation
                                                        onClick={() => {
                                                            setOpen(true);
                                                        }}
                                                    >
                                                        Reset password
                                                    </Button>
                                                    <Confirm
                                                        title="Reset password"
                                                        description="We'll send an email to you with a link to reset your password."
                                                        okText="Send link"
                                                        cancelText="Cancel"
                                                        open={open}
                                                        onOk={mutate}
                                                        onClose={() => {
                                                            setOpen(false);
                                                        }}
                                                        onCancel={() => {
                                                            setOpen(false);
                                                        }}
                                                        okButtonProps={{
                                                            color: 'primary',
                                                            loading
                                                        }}
                                                    />
                                                </Fragment>
                                            )}
                                        </Mutation>
                                    )}
                                </UseBooleanState>
                            </Box>
                        </Card>
                        <Card elevation={0}>
                            <Box padding={2}>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    {user.profileImage
                                        ?
                                        <Avatar
                                            src={`${process.env.REACT_APP_CDN_URL}/fit-in/160x160/${user.profileImage}`}
                                            sx={{
                                                width: 40,
                                                height: 40
                                            }}
                                        />
                                        :
                                        <Avatar
                                            sx={{
                                                width: 40,
                                                height: 40,
                                                backgroundColor: 'background.default',
                                                color: 'text.disabled'
                                            }}
                                        >
                                            <User />
                                        </Avatar>
                                    }
                                    <Typography color="text.secondary">
                                        @{user.username}
                                    </Typography>
                                </Stack>
                            </Box>
                            <Divider />
                            <Box padding={2}>
                                <Stack
                                    justifyContent="space-between"
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <div>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            Current plan
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            Free
                                        </Typography>
                                    </div>
                                    <Button
                                        component={Link}
                                        to="/upgrade-to-pro"
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                    >
                                        Upgrade to PRO
                                    </Button>
                                </Stack>
                            </Box>
                        </Card>
                        <Card elevation={0}>
                            <Box padding={2}>
                                <Grid
                                    columnSpacing={2}
                                    alignItems="center"
                                    container
                                >
                                    <Grid
                                        xs={8}
                                        item
                                    >
                                        <Typography
                                            variant="body1"
                                            color="text.secondary"
                                        >
                                            Display a sensitive content warning before visitors can view your profile.
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        item
                                    >
                                        <Mutation
                                            mutation={UPDATE_CURRENT_USER}
                                            onError={(err: ApolloError) => {

                                            }}
                                            onCompleted={(data: any) => {
                                                setUser(data.updateCurrentUser);
                                            }}
                                        >
                                            {(mutate: MutationFunction, { loading }: MutationResult) => (
                                                <TextField
                                                    defaultValue={user.contentWarning}
                                                    disabled={loading}
                                                    onChange={e => {
                                                        const variables = {
                                                            contentWarning: e.target.value
                                                        };
                                                        mutate({
                                                            variables
                                                        });
                                                    }}
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    select
                                                >
                                                    <MenuItem value="none">
                                                        No
                                                    </MenuItem>
                                                    <MenuItem value="sensitive">
                                                        Sensitive content
                                                    </MenuItem>
                                                    <MenuItem value="eighteen-plus">
                                                        18+
                                                    </MenuItem>
                                                    <MenuItem value="twenty-one-plus">
                                                        21+
                                                    </MenuItem>
                                                    <MenuItem value="twenty-five-plus">
                                                        25+
                                                    </MenuItem>
                                                </TextField>
                                            )}
                                        </Mutation>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Card>
                        <Card elevation={0}>
                            <Box padding={2}>
                                <UseBooleanState defaultValue={false}>
                                    {(open, setOpen) => (
                                        <Mutation
                                            mutation={DELETE_CURRENT_USER}
                                            onError={(err: ApolloError) => {

                                            }}
                                            onCompleted={() => {
                                                setAccessToken(null);
                                                setUser(null);
                                                enqueueSnackbar('Done');
                                            }}
                                        >
                                            {(mutate: MutationFunction, { loading }: MutationResult) => (
                                                <Fragment>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        disableElevation
                                                        onClick={() => {
                                                            setOpen(true);
                                                        }}
                                                    >
                                                        Delete account
                                                    </Button>
                                                    <Confirm
                                                        title="Delete account"
                                                        description="To confirm deletion, enter your email address in the text input field."
                                                        okText="Yes, delete it"
                                                        cancelText="Cancel"
                                                        confirmationText={user.email}
                                                        open={open}
                                                        onOk={mutate}
                                                        onClose={() => {
                                                            setOpen(false);
                                                        }}
                                                        onCancel={() => {
                                                            setOpen(false);
                                                        }}
                                                        okButtonProps={{
                                                            color: 'error',
                                                            loading
                                                        }}
                                                    />
                                                </Fragment>
                                            )}
                                        </Mutation>
                                    )}
                                </UseBooleanState>
                            </Box>
                        </Card>
                    </Stack>
                </Box>
            </Container>
        </Fragment>
    );
});