import { Box, Card, Skeleton, Stack } from '@mui/material';

export const AddonSkeleton = (): JSX.Element => {
    return (
        <Card elevation={0}>
            <Box padding={2}>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                >
                    <Skeleton
                        variant="circular"
                        width={34}
                        height={34}
                    />
                    <div>
                        <Skeleton
                            variant="rounded"
                            width={90}
                            height={20}
                        />
                        <Skeleton
                            variant="rounded"
                            width={160}
                            height={20}
                            sx={{
                                marginTop: 1
                            }}
                        />
                        <Skeleton
                            variant="rounded"
                            width={40}
                            height={24}
                            sx={{
                                marginTop: 1
                            }}
                        />
                    </div>
                </Stack>
            </Box>
        </Card>
    );
};