import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

type ConfirmProps = {
    open: boolean;
    onClose: () => void;
    title: string;
    description: string;
    okText: string;
    cancelText: string;
    confirmationText?: string;
    onOk: () => void;
    onCancel: () => void;
    okButtonProps?: Omit<LoadingButtonProps, 'variant' | 'onClick' | 'disableElevation'>;
};

export const Confirm = (props: ConfirmProps): JSX.Element => {
    const {
        open,
        onClose,
        title,
        description,
        okText,
        cancelText,
        confirmationText,
        onOk,
        onCancel,
        okButtonProps = {}
    } = props;

    const [
        inputText,
        inputTextSetter
    ] = useState('');

    useEffect(() => {
        if (open) {
            inputTextSetter('');
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {description}
                    {confirmationText && (
                        <TextField
                            placeholder={confirmationText}
                            value={inputText}
                            onChange={e => inputTextSetter(e.target.value)}
                            variant="outlined"
                            fullWidth
                            sx={{
                                marginTop: 4
                            }}
                        />
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    {...okButtonProps}
                    disabled={Boolean(confirmationText) && confirmationText !== inputText}
                    variant="contained"
                    onClick={onOk}
                    disableElevation
                >
                    {okText}
                </LoadingButton>
                <Button
                    onClick={onCancel}
                    color="inherit"
                >
                    {cancelText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};