import { ApolloError, gql, MutationFunction, MutationResult } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Container, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createUseState } from '../components/common/UseState';
import { getValidationErrorMessage, parseErrors } from '../lib/helpers';
import { setUser } from '../redux/auth/actions';
import { RootState } from '../redux/configureStore';
import { ValidationError } from '../types';

const UseValidationErrorsState = createUseState<ValidationError[]>();

const UPDATE_CURRENT_USER = gql`
    mutation UpdateCurrentUser($username: String) {
        updateCurrentUser(input: {
            username: $username
        }) {
            id
            name
            username
            profileImage
            bio
            contentWarning
            theme
            email
            emailVerified
        }
    }
`;

type ChangeUsernameProps = {
    setUser: (user: any) => void;
    user: any;
};

const mapStateToProps = (state: RootState) => ({
    user: state.auth.user
});

const mapDispatchToProps = {
    setUser
};

export const ChangeUsername = connect(mapStateToProps, mapDispatchToProps)((props: ChangeUsernameProps): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar();
    const {
        setUser,
        user
    } = props;

    return (
        <Fragment>
            <Helmet>
                <title>
                    Edit username - Paiz.io
                </title>
            </Helmet>
            <Container maxWidth="sm">
                <Box
                    margin={{
                        xs: '32px 0 16px',
                        sm: '32px 0 24px'
                    }}
                >
                    <Typography
                        variant="h4"
                        textAlign="center"
                        fontSize={{
                            xs: 24,
                            md: 34
                        }}
                        sx={{
                            marginBottom: 2
                        }}
                    >
                        Edit username
                    </Typography>
                    <UseValidationErrorsState defaultValue={[]}>
                        {(errors, setErrors) => (
                            <Mutation
                                mutation={UPDATE_CURRENT_USER}
                                onError={(err: ApolloError) => {
                                    if (err.message === 'Bad Request Exception') {
                                        setErrors(parseErrors(err));
                                    }
                                }}
                                onCompleted={(data: any) => {
                                    setUser(data.updateCurrentUser);
                                    enqueueSnackbar('Done');
                                }}
                            >
                                {(mutate: MutationFunction, { loading }: MutationResult) => (
                                    <Formik
                                        initialValues={{
                                            username: user.username
                                        }}
                                        onSubmit={values => {
                                            setErrors([]);
                                            mutate({
                                                variables: values
                                            });
                                        }}
                                    >
                                        {({ values, handleChange, handleSubmit }) => {
                                            return (
                                                <form onSubmit={handleSubmit}>
                                                    <TextField
                                                        name="username"
                                                        value={values.username}
                                                        onChange={handleChange}
                                                        error={errors.some(({ property }) => property === 'username')}
                                                        helperText={getValidationErrorMessage(errors, 'username')}
                                                        variant="outlined"
                                                        margin="normal"
                                                        placeholder="username"
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    paiz.io/
                                                                </InputAdornment>
                                                            ),
                                                            sx: {
                                                                backgroundColor: 'background.paper'
                                                            }
                                                        }}
                                                    />
                                                    <Stack
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        direction="row"
                                                        spacing={1}
                                                        sx={{
                                                            marginTop: 2
                                                        }}
                                                    >
                                                        <LoadingButton
                                                            variant="contained"
                                                            color="primary"
                                                            size="large"
                                                            type="submit"
                                                            disabled={values.username === user.username}
                                                            loading={loading}
                                                            disableElevation
                                                        >
                                                            Update
                                                        </LoadingButton>
                                                        <Button
                                                            component={Link}
                                                            to="/settings"
                                                            variant="outlined"
                                                            color="inherit"
                                                            size="large"
                                                        >
                                                            Back
                                                        </Button>
                                                    </Stack>
                                                </form>
                                            );
                                        }}
                                    </Formik>
                                )}
                            </Mutation>
                        )}
                    </UseValidationErrorsState>
                </Box>
            </Container>
        </Fragment>
    );
});