import { Box, Container, Typography } from '@mui/material';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

export const PrivacyPolicy = (): JSX.Element => {
    return (
        <Fragment>
            <Helmet>
                <title>
                    Privacy policy - Paiz.io
                </title>
            </Helmet>
            <Container maxWidth="xl">
                <Box
                    marginY={{
                        xs: 11,
                        sm: 16,
                        lg: 20
                    }}
                >
                    <Typography
                        variant="h4"
                        fontSize={{
                            xs: 24,
                            md: 34
                        }}
                    >
                        Privacy policy
                    </Typography>
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                            marginTop: 4
                        }}
                    >
                        Data
                    </Typography>
                    <Typography
                        textAlign="justify"
                        gutterBottom
                    >
                        We care about the privacy of our users and Profile Visitors.
                    </Typography>
                    <Typography
                        textAlign="justify"
                        gutterBottom
                    >
                        All data (and any IP rights in data) that we or the Platform create or generate based on your use (or profile visitors', or other users' use) of the Platform or content ("Data") will be owned by us. We may provide Data or visualisations of Data to you as part of the service we offer on the Platform (we call this "Data Analytics"). We make no representations or warranties as to the accuracy or completeness of the Data Analytics, but we'll try to make it as accurate and complete as we can.
                    </Typography>
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                            marginTop: 4
                        }}
                    >
                        Your visitors & customers
                    </Typography>
                    <Typography
                        textAlign="justify"
                        gutterBottom
                    >
                        You're responsible for your profile visitors, including customers who purchase goods or services through your Paiz (collectively "End Users").
                    </Typography>
                    <Typography
                        textAlign="justify"
                        gutterBottom
                    >
                        You're solely responsible for (i) the way in which End Users use and interact with your profile and your content; (ii) complying with all laws in relation to your End Users, and all transactions between you and End Users made through your Paiz (e.g. via our "Commerce" or "Payment lock" features).
                    </Typography>
                    <Typography
                        textAlign="justify"
                        gutterBottom
                    >
                        You also agree that any donations made to you via our "Support Me" feature are made voluntarily, without exchange for any good or service. You must use this feature solely to collect donations for yourself (and not to collect funds for charities or other causes).
                    </Typography>
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                            marginTop: 4
                        }}
                    >
                        Confidentiality
                    </Typography>
                    <Typography
                        textAlign="justify"
                        gutterBottom
                    >
                        If we share any confidential information about us or the Platform with you, you must keep it secret and secure.
                    </Typography>
                    <Typography
                        textAlign="justify"
                    >
                        From time to time, we may share info with you that is confidential (e.g. we may reveal new and upcoming features to you if you take part in beta testing with us). You must keep this info secret and secure and use reasonable measures to prevent others from accessing it.
                    </Typography>
                </Box>
            </Container>
        </Fragment>
    );
};