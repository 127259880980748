import { Box, Button, Card, Container, Stack, Typography, useTheme } from '@mui/material';
import { Fragment } from 'react';
import { Lock } from 'react-feather';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

export const Analytics = (): JSX.Element => {
    const { palette } = useTheme();

    return (
        <Fragment>
            <Helmet>
                <title>
                    Analytics - Paiz.io
                </title>
            </Helmet>
            <Container maxWidth="sm">
                <Box
                    margin={{
                        xs: '32px 0 16px',
                        sm: '32px 0 24px'
                    }}
                >
                    <Typography
                        variant="h4"
                        textAlign="center"
                        fontSize={{
                            xs: 24,
                            md: 34
                        }}
                    >
                        Analytics
                    </Typography>
                    <Card
                        elevation={0}
                        sx={{
                            marginTop: 4
                        }}
                    >
                        <Box padding={3}>
                            <Stack
                                alignItems="center"
                                spacing={3}
                                sx={{
                                    marginTop: 1
                                }}
                            >
                                <Lock
                                    size={60}
                                    color={palette.primary.main}
                                />
                                <Stack
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Typography
                                        variant="body1"
                                        color="text.secondary"
                                        textAlign="center"
                                    >
                                        You need to upgrade to PRO to use PRO features.
                                    </Typography>
                                    <Button
                                        component={Link}
                                        to="/upgrade-to-pro"
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                    >
                                        Upgrade to PRO
                                    </Button>
                                </Stack>
                            </Stack>
                        </Box>
                    </Card>
                </Box>
            </Container>
        </Fragment>
    );
};