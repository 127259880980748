import { ApolloError, gql, MutationFunction, MutationResult } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { LoadingButton } from '@mui/lab';
import { Box, Container, Link as MuiLink, Stack, SvgIcon, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import { Fragment } from 'react';
import { LogIn } from 'react-feather';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createUseState } from '../components/common/UseState';
import { getValidationErrorMessage, parseErrors } from '../lib/helpers';
import { setAccessToken, setUser } from '../redux/auth/actions';
import { ValidationError } from '../types';

const UseValidationErrorsState = createUseState<ValidationError[]>();

const SIGN_IN = gql`
    mutation SignIn(
        $email: String
        $password: String
    ) {
        signIn(input: {
            email: $email
            password: $password
        }) {
            accessToken
            user {
                id
                name
                username
                profileImage
                bio
                contentWarning
                theme
                email
                emailVerified
            }
        }
    }
`;

type SigninProps = {
    setAccessToken: (accessToken: string | null) => void;
    setUser: (user: any) => void;
};

const mapDispatchToProps = {
    setAccessToken,
    setUser
};

export const Signin = connect(null, mapDispatchToProps)((props: SigninProps): JSX.Element => {
    return (
        <Fragment>
            <Helmet>
                <title>
                    Signin - Paiz.io
                </title>
            </Helmet>
            <Container maxWidth="xs">
                <Box paddingY={20}>
                    <Typography
                        variant="h4"
                        textAlign="center"
                        fontSize={{
                            xs: 24,
                            md: 34
                        }}
                        sx={{
                            marginBottom: 2
                        }}
                    >
                        Sign in to your<br />Paiz
                    </Typography>
                    <UseValidationErrorsState defaultValue={[]}>
                        {(errors, setErrors) => (
                            <Mutation
                                mutation={SIGN_IN}
                                onError={(err: ApolloError) => {
                                    if (err.message === 'Bad Request Exception') {
                                        setErrors(parseErrors(err));
                                    }
                                }}
                                onCompleted={(data: any) => {
                                    props.setAccessToken(data.signIn.accessToken);
                                    props.setUser(data.signIn.user);
                                }}
                            >
                                {(mutate: MutationFunction, { loading }: MutationResult) => (
                                    <Formik
                                        initialValues={{
                                            email: '',
                                            password: ''
                                        }}
                                        onSubmit={values => {
                                            setErrors([]);
                                            mutate({
                                                variables: values
                                            });
                                        }}
                                    >
                                        {({ values, handleChange, handleSubmit }) => {
                                            return (
                                                <form onSubmit={handleSubmit}>
                                                    <TextField
                                                        name="email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        error={errors.some(({ property }) => property === 'email')}
                                                        helperText={getValidationErrorMessage(errors, 'email')}
                                                        label="Email address"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                    />
                                                    <TextField
                                                        name="password"
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        error={errors.some(({ property }) => property === 'password')}
                                                        helperText={getValidationErrorMessage(errors, 'password')}
                                                        type="password"
                                                        label="Password"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                    />
                                                    <Stack alignItems="flex-end">
                                                        <MuiLink
                                                            component={Link}
                                                            to="/"
                                                            color="text.secondary"
                                                            underline="none"
                                                        >
                                                            Forgot your password?
                                                        </MuiLink>
                                                    </Stack>
                                                    <Stack
                                                        alignItems="center"
                                                        sx={{
                                                            marginTop: 4
                                                        }}
                                                    >
                                                        <LoadingButton
                                                            variant="contained"
                                                            color="primary"
                                                            size="large"
                                                            type="submit"
                                                            loading={loading}
                                                            disableElevation
                                                            endIcon={
                                                                <SvgIcon>
                                                                    <LogIn />
                                                                </SvgIcon>
                                                            }
                                                        >
                                                            Sign in
                                                        </LoadingButton>
                                                    </Stack>
                                                    <Typography
                                                        color="text.secondary"
                                                        textAlign="center"
                                                        sx={{
                                                            marginTop: 4
                                                        }}
                                                    >
                                                        Don't have an account? <MuiLink component={Link} to="/signup" underline="none">Create one</MuiLink>
                                                    </Typography>
                                                </form>
                                            );
                                        }}
                                    </Formik>
                                )}
                            </Mutation>
                        )}
                    </UseValidationErrorsState>
                </Box>
            </Container>
        </Fragment>
    );
});