export const setAccessToken = (accessToken: string | null) => ({
    type: 'SET_AUTH',
    payload: {
        accessToken
    }
});

export const setUser = (user: any) => ({
    type: 'SET_AUTH',
    payload: {
        user
    }
});