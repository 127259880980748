import type { } from '@mui/lab/themeAugmentation';
import { createTheme } from '@mui/material';

export const dark = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#918cf2'
        },
        background: {
            default: '#0e1015',
            paper: '#14171e'
        }
    },
    shape: {
        borderRadius: 8
    },
    typography: {
        h1: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h2: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h3: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h4: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h5: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h6: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        subtitle1: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        subtitle2: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        body1: {
            fontFamily: 'Nunito',
            fontWeight: 400
        },
        body2: {
            fontFamily: 'Nunito',
            fontWeight: 400
        },
        button: {
            fontFamily: 'Nunito',
            fontWeight: 700,
            textTransform: 'none'
        },
        caption: {
            fontFamily: 'Nunito',
            fontWeight: 400
        },
        overline: {
            fontFamily: 'Nunito',
            fontWeight: 400
        }
    }
});