import { createReducer, PayloadAction } from '@reduxjs/toolkit';

export type ThemeState = {
    mode: 'dark' | 'light';
};

const initialState: ThemeState = {
    mode: 'dark'
};

const handlers = {
    SET_THEME(state: ThemeState, { payload }: PayloadAction<Partial<ThemeState>>) {
        return {
            ...state,
            ...payload
        };
    }
};

export const theme = createReducer(
    initialState,
    handlers
);