import { Box, Button, Card, Chip, Stack, SvgIcon, Typography } from '@mui/material';
import { Check } from 'react-feather';
import { NumericFormat } from 'react-number-format';
import { Link } from 'react-router-dom';

type PricingPlanProps = {
    name: string;
    duration?: string;
    monthlyPrice: number;
    features: string[];
    isRecommended?: boolean;
};

export const PricingPlan = (props: PricingPlanProps): JSX.Element => {
    const {
        name,
        duration,
        monthlyPrice,
        features,
        isRecommended = false
    } = props;

    return (
        <Card
            elevation={0}
            sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
        >
            <Box flex={1}>
                <Box padding={2}>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}
                    >
                        <Typography variant="subtitle1">
                            {name}
                        </Typography>
                        {duration && (
                            <Chip
                                label={duration}
                                variant="outlined"
                                color="primary"
                                size="small"
                            />
                        )}
                        {isRecommended && (
                            <Chip
                                label="recommended"
                                variant="outlined"
                                color="success"
                                size="small"
                            />
                        )}
                    </Stack>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}
                        sx={{
                            marginTop: 4
                        }}
                    >
                        <Typography variant="h4">
                            <NumericFormat
                                value={monthlyPrice}
                                displayType="text"
                                thousandSeparator=","
                                prefix="$"
                            />
                        </Typography>
                        <Typography
                            variant="body1"
                            color="text.secondary"
                        >
                            per month
                        </Typography>
                    </Stack>
                    <Stack
                        spacing={1}
                        sx={{
                            marginTop: 4
                        }}
                    >
                        {features.map((feature, index) => (
                            <Stack
                                key={index}
                                alignItems="center"
                                direction="row"
                                spacing={1}
                            >
                                <SvgIcon color="primary">
                                    <Check />
                                </SvgIcon>
                                <Typography variant="body1">
                                    {feature}
                                </Typography>
                            </Stack>
                        ))}
                    </Stack>
                </Box>
            </Box>
            <Box padding={2}>
                {monthlyPrice === 0
                    ?
                    <Button
                        component={Link}
                        to="/signup"
                        variant="outlined"
                        color="inherit"
                        size="large"
                        disableElevation
                        fullWidth
                    >
                        Join for free
                    </Button>
                    :
                    <Button
                        component={Link}
                        to="/upgrade-to-pro"
                        variant="contained"
                        size="large"
                        disableElevation
                        fullWidth
                    >
                        Get PRO
                    </Button>
                }
            </Box>
        </Card>
    );
};