import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { withGuest } from '../hoc/withGuest';
import { RootState } from '../redux/configureStore';
import { dark } from '../themes/dark';
import { light } from '../themes/light';

type GuestProps = {
    mode: 'dark' | 'light';
};

const mapStateToProps = (state: RootState) => ({
    mode: state.theme.mode
});

export const Guest = connect(mapStateToProps)(withGuest((props: GuestProps): JSX.Element => {
    return (
        <ThemeProvider theme={props.mode === 'dark' ? dark : light}>
            <Helmet>
                <meta name="theme-color" content={props.mode === 'dark' ? '#0e1015' : '#ffffff'} />
            </Helmet>
            <CssBaseline />
            <Outlet />
            <ScrollRestoration />
        </ThemeProvider>
    );
}));