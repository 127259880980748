import { Box, Container, Grid, IconButton, Link as MuiLink, Stack, SvgIcon, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { BrandFacebook, BrandInstagram, BrandTwitter, BrandYoutube } from 'tabler-icons-react';
import { RootState } from '../../redux/configureStore';

type AppFooterProps = {
    mode: 'dark' | 'light';
};

const mapStateToProps = (state: RootState) => ({
    mode: state.theme.mode
});

export const AppFooter = connect(mapStateToProps)((props: AppFooterProps): JSX.Element => {
    return (
        <Box
            sx={{
                paddingBottom: 4
            }}
        >
            <Container maxWidth="xl">
                <Grid
                    justifyContent="space-between"
                    columnSpacing={4}
                    rowSpacing={10}
                    container
                >
                    <Grid
                        xs={12}
                        sm={4}
                        item
                    >
                        <MuiLink
                            className="clearfix"
                            component={Link}
                            to="/"
                            variant="h5"
                            underline="none"
                        >
                            <Box
                                component="img"
                                alt="paiz logo"
                                src={props.mode === 'dark' ? '/static/images/logo-white.png' : '/static/images/logo.png'}
                                sx={{
                                    display: 'inline-block',
                                    float: 'left',
                                    height: {
                                        xs: 40,
                                        sm: 48
                                    }
                                }}
                            />
                        </MuiLink>
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{
                                marginTop: 4,
                                color: 'text.secondary'
                            }}
                        >
                            <IconButton
                                component="a"
                                target="_blank"
                                rel="noopener noreferrer"
                                color="inherit"
                                href="https://facebook.com"
                                sx={{
                                    borderRadius: 1,
                                    backgroundColor: 'background.paper'
                                }}
                            >
                                <SvgIcon fontSize="inherit">
                                    <BrandFacebook strokeWidth={1} />
                                </SvgIcon>
                            </IconButton>
                            <IconButton
                                component="a"
                                target="_blank"
                                rel="noopener noreferrer"
                                color="inherit"
                                href="https://instagram.com"
                                sx={{
                                    borderRadius: 1,
                                    backgroundColor: 'background.paper'
                                }}
                            >
                                <SvgIcon fontSize="inherit">
                                    <BrandInstagram strokeWidth={1} />
                                </SvgIcon>
                            </IconButton>
                            <IconButton
                                component="a"
                                target="_blank"
                                rel="noopener noreferrer"
                                color="inherit"
                                href="https://twitter.com"
                                sx={{
                                    borderRadius: 1,
                                    backgroundColor: 'background.paper'
                                }}
                            >
                                <SvgIcon fontSize="inherit">
                                    <BrandTwitter strokeWidth={1} />
                                </SvgIcon>
                            </IconButton>
                            <IconButton
                                component="a"
                                target="_blank"
                                rel="noopener noreferrer"
                                color="inherit"
                                href="https://youtube.com"
                                sx={{
                                    borderRadius: 1,
                                    backgroundColor: 'background.paper'
                                }}
                            >
                                <SvgIcon fontSize="inherit">
                                    <BrandYoutube strokeWidth={1} />
                                </SvgIcon>
                            </IconButton>
                        </Stack>
                    </Grid>
                    <Grid
                        columnSpacing={4}
                        xs={12}
                        sm={6}
                        md={5}
                        lg={4}
                        container
                        item
                    >
                        <Grid
                            xs={4}
                            sm={5}
                            md={6}
                            item
                        >
                            <Typography variant="subtitle1">
                                Company
                            </Typography>
                            <Stack
                                spacing={1}
                                sx={{
                                    marginTop: 2
                                }}
                            >
                                <MuiLink
                                    href="https://blog.paiz.io"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    color="text.secondary"
                                    underline="none"
                                >
                                    Blog
                                </MuiLink>
                                <MuiLink
                                    component={Link}
                                    to="/paiz.io"
                                    color="text.secondary"
                                    underline="none"
                                >
                                    Contact us
                                </MuiLink>
                            </Stack>
                        </Grid>
                        <Grid
                            xs={8}
                            sm={7}
                            md={6}
                            item
                        >
                            <Typography variant="subtitle1">
                                Support
                            </Typography>
                            <Stack
                                spacing={1}
                                sx={{
                                    marginTop: 2
                                }}
                            >
                                <MuiLink
                                    component={Link}
                                    to="/signup"
                                    color="text.secondary"
                                    underline="none"
                                >
                                    Sign up for free
                                </MuiLink>
                                <MuiLink
                                    component={Link}
                                    to="/#faq"
                                    color="text.secondary"
                                    underline="none"
                                    onClick={() => {
                                        scroller.scrollTo('faq', {
                                            smooth: true
                                        });
                                    }}
                                >
                                    FAQs
                                </MuiLink>
                                <MuiLink
                                    component={Link}
                                    to="/privacy-policy"
                                    color="text.secondary"
                                    underline="none"
                                >
                                    Privacy policy
                                </MuiLink>
                                <MuiLink
                                    component={Link}
                                    to="/terms-and-conditions"
                                    color="text.secondary"
                                    underline="none"
                                >
                                    Terms and Conditions
                                </MuiLink>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Box marginTop={10}>
                    <Typography variant="subtitle1">
                        Meta Keys
                    </Typography>
                    <Typography
                        variant="body1"
                        color="text.disabled"
                    >
                        Ulaanbaatar, Mongolia
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
});