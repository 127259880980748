import { IconButton, IconButtonProps, SvgIcon, Tooltip } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Copy } from 'react-feather';
import { createUseState } from './UseState';

const UseBooleanState = createUseState<boolean>();

type CopyButtonProps = IconButtonProps & {
    text: string;
};

export const CopyButton = ({ text, ...props }: CopyButtonProps): JSX.Element => {

    return (
        <UseBooleanState defaultValue={false}>
            {(open, setOpen) => (
                <CopyToClipboard
                    text={text}
                    onCopy={() => {
                        setOpen(true);
                    }}
                >
                    <Tooltip
                        placement="top"
                        title="Copied!"
                        open={open}
                        leaveDelay={1000}
                        disableFocusListener
                        disableTouchListener
                        arrow
                        onClose={() => {
                            setOpen(false);
                        }}
                        PopperProps={{
                            disablePortal: true
                        }}
                    >
                        <IconButton {...props}>
                            <SvgIcon>
                                <Copy strokeWidth={1} />
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                </CopyToClipboard>
            )}
        </UseBooleanState>
    );
};