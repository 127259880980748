import { ApolloError, gql, MutationFunction, MutationResult } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { LoadingButton } from '@mui/lab';
import { Box, Container, InputAdornment, Link as MuiLink, Stack, SvgIcon, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import { Fragment } from 'react';
import { ArrowRight } from 'react-feather';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { createUseState } from '../components/common/UseState';
import { getValidationErrorMessage, parseErrors } from '../lib/helpers';
import { setAccessToken, setUser } from '../redux/auth/actions';
import { ValidationError } from '../types';

const UseValidationErrorsState = createUseState<ValidationError[]>();

const SIGN_UP = gql`
    mutation SignUp(
        $username: String
        $email: String
        $password: String
        $passwordConfirmation: String
    ) {
        signUp(input: {
            username: $username
            email: $email
            password: $password
            passwordConfirmation: $passwordConfirmation
        }) {
            accessToken
            user {
                id
                name
                username
                profileImage
                bio
                contentWarning
                theme
                email
                emailVerified
            }
        }
    }
`;

type SignupProps = {
    setAccessToken: (accessToken: string | null) => void;
    setUser: (user: any) => void;
};

const mapDispatchToProps = {
    setAccessToken,
    setUser
};

export const Signup = connect(null, mapDispatchToProps)((props: SignupProps): JSX.Element => {
    const [
        searchParams
    ] = useSearchParams();

    return (
        <Fragment>
            <Helmet>
                <title>
                    Signup - Paiz.io
                </title>
            </Helmet>
            <Container maxWidth="xs">
                <Box paddingY={20}>
                    <Typography
                        variant="h4"
                        textAlign="center"
                        fontSize={{
                            xs: 24,
                            md: 34
                        }}
                        sx={{
                            marginBottom: 2
                        }}
                    >
                        Just for a few<br />minutes
                    </Typography>
                    <UseValidationErrorsState defaultValue={[]}>
                        {(errors, setErrors) => (
                            <Mutation
                                mutation={SIGN_UP}
                                onError={(err: ApolloError) => {
                                    if (err.message === 'Bad Request Exception') {
                                        setErrors(parseErrors(err));
                                    }
                                }}
                                onCompleted={(data: any) => {
                                    props.setAccessToken(data.signUp.accessToken);
                                    props.setUser(data.signUp.user);
                                }}
                            >
                                {(mutate: MutationFunction, { loading }: MutationResult) => (
                                    <Formik
                                        initialValues={{
                                            username: searchParams.get('username') ?? '',
                                            email: '',
                                            password: '',
                                            passwordConfirmation: ''
                                        }}
                                        onSubmit={values => {
                                            setErrors([]);
                                            mutate({
                                                variables: values
                                            });
                                        }}
                                    >
                                        {({ values, handleChange, handleSubmit }) => {
                                            return (
                                                <form onSubmit={handleSubmit}>
                                                    <TextField
                                                        name="username"
                                                        value={values.username}
                                                        onChange={handleChange}
                                                        error={errors.some(({ property }) => property === 'username')}
                                                        helperText={getValidationErrorMessage(errors, 'username')}
                                                        variant="outlined"
                                                        margin="normal"
                                                        placeholder="username"
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    paiz.io/
                                                                </InputAdornment>
                                                            ),
                                                            sx: {
                                                                backgroundColor: 'background.paper'
                                                            }
                                                        }}
                                                    />
                                                    <TextField
                                                        name="email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        error={errors.some(({ property }) => property === 'email')}
                                                        helperText={getValidationErrorMessage(errors, 'email')}
                                                        label="Email address"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        autoFocus={Boolean(values.username)}
                                                    />
                                                    <TextField
                                                        name="password"
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        error={errors.some(({ property }) => property === 'password')}
                                                        helperText={getValidationErrorMessage(errors, 'password')}
                                                        type="password"
                                                        label="Password"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                    />
                                                    <TextField
                                                        name="passwordConfirmation"
                                                        value={values.passwordConfirmation}
                                                        onChange={handleChange}
                                                        error={errors.some(({ property }) => property === 'passwordConfirmation')}
                                                        helperText={getValidationErrorMessage(errors, 'passwordConfirmation')}
                                                        type="password"
                                                        label="Confirm password"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                    />
                                                    <Typography
                                                        color="text.secondary"
                                                        textAlign="center"
                                                        sx={{
                                                            marginTop: 4
                                                        }}
                                                    >
                                                        By clicking Sign up, you agree to our <MuiLink component={Link} to="/" underline="none">Terms and conditions.</MuiLink>
                                                    </Typography>
                                                    <Stack
                                                        alignItems="center"
                                                        sx={{
                                                            marginTop: 2
                                                        }}
                                                    >
                                                        <LoadingButton
                                                            variant="contained"
                                                            color="primary"
                                                            size="large"
                                                            type="submit"
                                                            loading={loading}
                                                            disableElevation
                                                            endIcon={
                                                                <SvgIcon>
                                                                    <ArrowRight />
                                                                </SvgIcon>
                                                            }
                                                        >
                                                            Sign up
                                                        </LoadingButton>
                                                    </Stack>
                                                </form>
                                            );
                                        }}
                                    </Formik>
                                )}
                            </Mutation>
                        )}
                    </UseValidationErrorsState>
                </Box>
            </Container>
        </Fragment>
    );
});