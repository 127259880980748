import { LoadingButton } from '@mui/lab';
import { Button, Grid, IconButton, InputAdornment, MenuItem, Stack, TextField } from '@mui/material';
import { Formik } from 'formik';
import { X } from 'react-feather';
import { getValidationErrorMessage } from '../lib/helpers';
import { ValidationError } from '../types';

type SubmitButtonProps = {
    loading: boolean;
};

type Values = {
    title: string;
    firstName?: string;
    lastName?: string;
    organization?: string;
    position?: string;
    emailPrimary?: string;
    emailPrimaryType?: string;
    emailSecondary?: string;
    emailSecondaryType?: string;
    phonePrimary?: string;
    phonePrimaryType?: string;
    phoneSecondary?: string;
    phoneSecondaryType?: string;
    addressLine1?: string;
    addressLine2?: string;
    addressCity?: string;
    addressCountry?: string;
    addressState?: string;
    addressPostcode?: string;
};

type VCardFormProps = {
    onSubmit: (values: Values) => Promise<void> | void;
    onCancel: () => void;
    initialValues: Values;
    errors: ValidationError[];
    submitButtonProps?: SubmitButtonProps;
};

export const VCardForm = (props: VCardFormProps): JSX.Element => {
    const {
        submitButtonProps = {},
        onCancel,
        errors,
        ...otherProps
    } = props;

    return (
        <Formik {...otherProps}>
            {({ values, handleChange, handleSubmit, getFieldHelpers }) => (
                <form
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    noValidate
                >
                    <TextField
                        label="Title"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        error={errors.some(({ property }) => property === 'title')}
                        helperText={getValidationErrorMessage(errors, 'title')}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        required
                    />
                    <Grid
                        columnSpacing={2}
                        container
                    >
                        <Grid xs item>
                            <TextField
                                label="First name"
                                name="firstName"
                                value={values.firstName}
                                onChange={handleChange}
                                error={errors.some(({ property }) => property === 'firstName')}
                                helperText={getValidationErrorMessage(errors, 'firstName')}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid xs item>
                            <TextField
                                label="Last name"
                                name="lastName"
                                value={values.lastName}
                                onChange={handleChange}
                                error={errors.some(({ property }) => property === 'lastName')}
                                helperText={getValidationErrorMessage(errors, 'lastName')}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        label="Organization"
                        name="organization"
                        value={values.organization}
                        onChange={handleChange}
                        error={errors.some(({ property }) => property === 'organization')}
                        helperText={getValidationErrorMessage(errors, 'organization')}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                    <TextField
                        label="Position"
                        name="position"
                        value={values.position}
                        onChange={handleChange}
                        error={errors.some(({ property }) => property === 'position')}
                        helperText={getValidationErrorMessage(errors, 'position')}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                    <Grid
                        columnSpacing={2}
                        container
                    >
                        <Grid xs item>
                            <TextField
                                label="Type"
                                name="emailPrimaryType"
                                value={values.emailPrimaryType}
                                onChange={handleChange}
                                error={errors.some(({ property }) => property === 'emailPrimaryType')}
                                helperText={getValidationErrorMessage(errors, 'emailPrimaryType')}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                select
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {values.emailPrimaryType && (
                                                <IconButton
                                                    edge="end"
                                                    onClick={() => {
                                                        getFieldHelpers('emailPrimaryType').setValue('');
                                                    }}
                                                    sx={{
                                                        marginRight: '18px'
                                                    }}
                                                >
                                                    <X />
                                                </IconButton>
                                            )}
                                        </InputAdornment>
                                    )
                                }}
                            >
                                <MenuItem value="PERSONAL">
                                    Personal
                                </MenuItem>
                                <MenuItem value="WORK">
                                    Work
                                </MenuItem>
                                <MenuItem value="OTHER">
                                    Other
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid xs item>
                            <TextField
                                label="Email address"
                                name="emailPrimary"
                                value={values.emailPrimary}
                                onChange={handleChange}
                                error={errors.some(({ property }) => property === 'emailPrimary')}
                                helperText={getValidationErrorMessage(errors, 'emailPrimary')}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        columnSpacing={2}
                        container
                    >
                        <Grid xs item>
                            <TextField
                                label="Type"
                                name="emailSecondaryType"
                                value={values.emailSecondaryType}
                                onChange={handleChange}
                                error={errors.some(({ property }) => property === 'emailSecondaryType')}
                                helperText={getValidationErrorMessage(errors, 'emailSecondaryType')}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                select
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {values.emailSecondaryType && (
                                                <IconButton
                                                    edge="end"
                                                    onClick={() => {
                                                        getFieldHelpers('emailSecondaryType').setValue('');
                                                    }}
                                                    sx={{
                                                        marginRight: '18px'
                                                    }}
                                                >
                                                    <X />
                                                </IconButton>
                                            )}
                                        </InputAdornment>
                                    )
                                }}
                            >
                                <MenuItem value="PERSONAL">
                                    Personal
                                </MenuItem>
                                <MenuItem value="WORK">
                                    Work
                                </MenuItem>
                                <MenuItem value="OTHER">
                                    Other
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid xs item>
                            <TextField
                                label="Email address"
                                name="emailSecondary"
                                value={values.emailSecondary}
                                onChange={handleChange}
                                error={errors.some(({ property }) => property === 'emailSecondary')}
                                helperText={getValidationErrorMessage(errors, 'emailSecondary')}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        columnSpacing={2}
                        container
                    >
                        <Grid xs item>
                            <TextField
                                label="Type"
                                name="phonePrimaryType"
                                value={values.phonePrimaryType}
                                onChange={handleChange}
                                error={errors.some(({ property }) => property === 'phonePrimaryType')}
                                helperText={getValidationErrorMessage(errors, 'phonePrimaryType')}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                select
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {values.phonePrimaryType && (
                                                <IconButton
                                                    edge="end"
                                                    onClick={() => {
                                                        getFieldHelpers('phonePrimaryType').setValue('');
                                                    }}
                                                    sx={{
                                                        marginRight: '18px'
                                                    }}
                                                >
                                                    <X />
                                                </IconButton>
                                            )}
                                        </InputAdornment>
                                    )
                                }}
                            >
                                <MenuItem value="MOBILE">
                                    Mobile
                                </MenuItem>
                                <MenuItem value="WORK">
                                    Work
                                </MenuItem>
                                <MenuItem value="HOME">
                                    Home
                                </MenuItem>
                                <MenuItem value="OTHER">
                                    Other
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid xs item>
                            <TextField
                                label="Phone number"
                                name="phonePrimary"
                                value={values.phonePrimary}
                                onChange={handleChange}
                                error={errors.some(({ property }) => property === 'phonePrimary')}
                                helperText={getValidationErrorMessage(errors, 'phonePrimary')}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        columnSpacing={2}
                        container
                    >
                        <Grid xs item>
                            <TextField
                                label="Type"
                                name="phoneSecondaryType"
                                value={values.phoneSecondaryType}
                                onChange={handleChange}
                                error={errors.some(({ property }) => property === 'phoneSecondaryType')}
                                helperText={getValidationErrorMessage(errors, 'phoneSecondaryType')}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                select
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {values.phoneSecondaryType && (
                                                <IconButton
                                                    edge="end"
                                                    onClick={() => {
                                                        getFieldHelpers('phoneSecondaryType').setValue('');
                                                    }}
                                                    sx={{
                                                        marginRight: '18px'
                                                    }}
                                                >
                                                    <X />
                                                </IconButton>
                                            )}
                                        </InputAdornment>
                                    )
                                }}
                            >
                                <MenuItem value="MOBILE">
                                    Mobile
                                </MenuItem>
                                <MenuItem value="WORK">
                                    Work
                                </MenuItem>
                                <MenuItem value="HOME">
                                    Home
                                </MenuItem>
                                <MenuItem value="OTHER">
                                    Other
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid xs item>
                            <TextField
                                label="Phone number"
                                name="phoneSecondary"
                                value={values.phoneSecondary}
                                onChange={handleChange}
                                error={errors.some(({ property }) => property === 'phoneSecondary')}
                                helperText={getValidationErrorMessage(errors, 'phoneSecondary')}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        label="Street address"
                        name="addressLine1"
                        value={values.addressLine1}
                        onChange={handleChange}
                        error={errors.some(({ property }) => property === 'addressLine1')}
                        helperText={getValidationErrorMessage(errors, 'addressLine1')}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                    <TextField
                        label="Apartment, suite, etc."
                        name="addressLine2"
                        value={values.addressLine2}
                        onChange={handleChange}
                        error={errors.some(({ property }) => property === 'addressLine2')}
                        helperText={getValidationErrorMessage(errors, 'addressLine2')}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                    <TextField
                        label="Suburb/Town/City"
                        name="addressCity"
                        value={values.addressCity}
                        onChange={handleChange}
                        error={errors.some(({ property }) => property === 'addressCity')}
                        helperText={getValidationErrorMessage(errors, 'addressCity')}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                    <TextField
                        label="Country"
                        name="addressCountry"
                        value={values.addressCountry}
                        onChange={handleChange}
                        error={errors.some(({ property }) => property === 'addressCountry')}
                        helperText={getValidationErrorMessage(errors, 'addressCountry')}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                    <Grid
                        columnSpacing={2}
                        container
                    >
                        <Grid xs item>
                            <TextField
                                label="State/Province"
                                name="addressState"
                                value={values.addressState}
                                onChange={handleChange}
                                error={errors.some(({ property }) => property === 'addressState')}
                                helperText={getValidationErrorMessage(errors, 'addressState')}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid xs item>
                            <TextField
                                label="ZIP/Postal code"
                                name="addressPostcode"
                                value={values.addressPostcode}
                                onChange={handleChange}
                                error={errors.some(({ property }) => property === 'addressPostcode')}
                                helperText={getValidationErrorMessage(errors, 'addressPostcode')}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Stack
                        justifyContent="flex-end"
                        alignItems="center"
                        direction="row"
                        spacing={1}
                        sx={{
                            marginTop: 2
                        }}
                    >
                        <LoadingButton
                            {...submitButtonProps}
                            variant="contained"
                            color="primary"
                            type="submit"
                            disableElevation
                        >
                            Save
                        </LoadingButton>
                        <Button
                            variant="outlined"
                            color="inherit"
                            disableElevation
                            onClick={onCancel}
                            sx={{
                                border: theme => `1px solid ${theme.palette.divider}`
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </form>
            )}
        </Formik>
    );
};