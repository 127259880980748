import { AppBar, Avatar, Box, Button, Container, Drawer, IconButton, Link as MuiLink, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, SvgIcon, Toolbar } from '@mui/material';
import { Fragment, useState } from 'react';
import { LogOut, Menu as MenuIcon, Moon, Sun, User } from 'react-feather';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { setAccessToken, setUser } from '../../redux/auth/actions';
import { RootState } from '../../redux/configureStore';
import { setMode } from '../../redux/theme/actions';
import { Spacer } from '../common/Spacer';

type AppHeaderProps = {
    setAccessToken: (accessToken: string | null) => void;
    setUser: (user: any) => void;
    setMode: (mode: 'dark' | 'light') => void;
    user: any;
    mode: 'dark' | 'light';
};

const mapStateToProps = (state: RootState) => ({
    user: state.auth.user,
    mode: state.theme.mode
});

const mapDispatchToProps = {
    setAccessToken,
    setUser,
    setMode
};

export const AppHeader = connect(mapStateToProps, mapDispatchToProps)((props: AppHeaderProps): JSX.Element => {
    const {
        setAccessToken,
        setUser,
        setMode,
        user,
        mode
    } = props;
    const [
        anchorEl,
        setAnchorEl
    ] = useState<null | HTMLElement>(null);
    const [
        open,
        setOpen
    ] = useState(false);

    return (
        <Fragment>
            <AppBar
                position="absolute"
                color="transparent"
                elevation={0}
                enableColorOnDark
            >
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <MuiLink
                            className="clearfix"
                            component={Link}
                            to="/"
                            variant="h5"
                            underline="none"
                        >
                            <Box
                                component="img"
                                alt="paiz logo"
                                src={mode === 'dark' ? '/static/images/logo-white.png' : '/static/images/logo.png'}
                                sx={{
                                    display: 'inline-block',
                                    float: 'left',
                                    height: {
                                        xs: 40,
                                        md: 48
                                    }
                                }}
                            />
                        </MuiLink>
                        <Spacer />
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={2}
                            sx={{
                                color: 'text.secondary',
                                display: {
                                    sm: 'none'
                                }
                            }}
                        >
                            <IconButton
                                color="inherit"
                                edge="start"
                                onClick={() => {
                                    setOpen(true);
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <IconButton
                                color="inherit"
                                onClick={() => {
                                    setMode(mode === 'dark' ? 'light' : 'dark');
                                }}
                            >
                                {mode === 'dark'
                                    ? <Sun />
                                    : <Moon />
                                }
                            </IconButton>
                        </Stack>
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={2}
                            sx={{
                                display: {
                                    xs: 'none',
                                    sm: 'flex'
                                }
                            }}
                        >
                            <Button
                                component={Link}
                                to="/"
                                color="inherit"
                            >
                                Home
                            </Button>
                            <Button
                                component={Link}
                                to="/#features"
                                color="inherit"
                                onClick={() => {
                                    scroller.scrollTo('features', {
                                        smooth: true
                                    });
                                }}
                                sx={{
                                    fontWeight: 400,
                                    color: 'text.secondary'
                                }}
                            >
                                Features
                            </Button>
                            <Button
                                component={Link}
                                to="/#pricing"
                                color="inherit"
                                onClick={() => {
                                    scroller.scrollTo('pricing', {
                                        smooth: true
                                    });
                                }}
                                sx={{
                                    fontWeight: 400,
                                    color: 'text.secondary'
                                }}
                            >
                                Pricing
                            </Button>
                            <Button
                                component={Link}
                                to="/#faq"
                                color="inherit"
                                onClick={() => {
                                    scroller.scrollTo('faq', {
                                        smooth: true
                                    });
                                }}
                                sx={{
                                    fontWeight: 400,
                                    color: 'text.secondary'
                                }}
                            >
                                FAQ
                            </Button>
                            {user === null
                                ?
                                <Fragment>
                                    <Button
                                        component={Link}
                                        to="/signin"
                                        variant="outlined"
                                        color="inherit"
                                    >
                                        Sign in
                                    </Button>
                                    <Button
                                        component={Link}
                                        to="/signup"
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                    >
                                        Sign up for free
                                    </Button>
                                </Fragment>
                                :
                                <Fragment>
                                    <Button
                                        component={Link}
                                        to="/links"
                                        variant="outlined"
                                        color="inherit"
                                    >
                                        Admin
                                    </Button>
                                    <IconButton
                                        size="small"
                                        onClick={e => {
                                            setAnchorEl(e.currentTarget);
                                        }}
                                    >
                                        {user.profileImage
                                            ?
                                            <Avatar
                                                src={`${process.env.REACT_APP_CDN_URL}/fit-in/160x160/${user.profileImage}`}
                                                sx={{
                                                    width: 40,
                                                    height: 40
                                                }}
                                            />
                                            :
                                            <Avatar
                                                sx={{
                                                    width: 40,
                                                    height: 40,
                                                    backgroundColor: 'background.default',
                                                    color: 'text.disabled'
                                                }}
                                            >
                                                <User />
                                            </Avatar>
                                        }
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={() => {
                                            setAnchorEl(null);
                                        }}
                                        PaperProps={{
                                            sx: {
                                                minWidth: 240
                                            }
                                        }}
                                        anchorOrigin={{
                                            horizontal: 'right',
                                            vertical: 'bottom'
                                        }}
                                        transformOrigin={{
                                            horizontal: 'right',
                                            vertical: 'top'
                                        }}
                                    >
                                        <MenuItem
                                            onClick={() => {
                                                setAccessToken(null);
                                                setUser(null);
                                            }}
                                        >
                                            <ListItemIcon>
                                                <SvgIcon>
                                                    <LogOut />
                                                </SvgIcon>
                                            </ListItemIcon>
                                            <ListItemText primary="Sign out" />
                                        </MenuItem>
                                    </Menu>
                                </Fragment>
                            }
                            <Box color="text.secondary">
                                <IconButton
                                    color="inherit"
                                    onClick={() => {
                                        setMode(mode === 'dark' ? 'light' : 'dark');
                                    }}
                                >
                                    {mode === 'dark'
                                        ? <Sun />
                                        : <Moon />
                                    }
                                </IconButton>
                            </Box>
                        </Stack>
                    </Toolbar>
                </Container>
            </AppBar>
            <Drawer
                variant="temporary"
                anchor="right"
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                ModalProps={{
                    keepMounted: true
                }}
                PaperProps={{
                    sx: {
                        width: 280,
                        boxSizing: 'border-box',
                        backgroundImage: 'none'
                    }
                }}
            >
                <List>
                    <ListItem disablePadding>
                        <ListItemButton
                            component={Link}
                            to="/"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <ListItemText
                                primary="Home"
                                primaryTypographyProps={{
                                    variant: 'subtitle1'
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            component={Link}
                            to="/#features"
                            onClick={() => {
                                scroller.scrollTo('features', {
                                    smooth: true
                                });
                                setOpen(false);
                            }}
                        >
                            <ListItemText
                                primary="Features"
                                primaryTypographyProps={{
                                    color: 'text.secondary'
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            component={Link}
                            to="/#pricing"
                            onClick={() => {
                                scroller.scrollTo('pricing', {
                                    smooth: true
                                });
                                setOpen(false);
                            }}
                        >
                            <ListItemText
                                primary="Pricing"
                                primaryTypographyProps={{
                                    color: 'text.secondary'
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            component={Link}
                            to="/#faq"
                            onClick={() => {
                                scroller.scrollTo('faq', {
                                    smooth: true
                                });
                                setOpen(false);
                            }}
                        >
                            <ListItemText
                                primary="FAQ"
                                primaryTypographyProps={{
                                    color: 'text.secondary'
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                    {user === null
                        ?
                        <Fragment>
                            <ListItem disablePadding>
                                <ListItemButton
                                    component={Link}
                                    to="/signin"
                                >
                                    <ListItemText
                                        primary="Sign in"
                                        primaryTypographyProps={{
                                            color: 'text.secondary'
                                        }}
                                    />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton
                                    component={Link}
                                    to="/signup"
                                >
                                    <ListItemText
                                        primary="Sign up for free"
                                        primaryTypographyProps={{
                                            color: 'text.secondary'
                                        }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        </Fragment>
                        :
                        <Fragment>
                            <ListItem disablePadding>
                                <ListItemButton
                                    component={Link}
                                    to="/links"
                                >
                                    <ListItemText primary="Dashboard" />
                                </ListItemButton>
                            </ListItem>
                        </Fragment>
                    }
                </List>
                {user && (
                    <Fragment>
                        <Spacer />
                        <Box padding={2}>
                            <Button
                                variant="outlined"
                                color="inherit"
                                fullWidth
                                startIcon={
                                    <SvgIcon>
                                        <LogOut />
                                    </SvgIcon>
                                }
                                onClick={() => {
                                    setAccessToken(null);
                                    setUser(null);
                                }}
                            >
                                Sign out
                            </Button>
                        </Box>
                    </Fragment>
                )}
            </Drawer>
        </Fragment>
    );
});