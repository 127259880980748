import { Button, Grid, Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import { QRCodeCanvas } from 'qrcode.react';
import { Download } from 'react-feather';

type PaizQrProps = {
    username: string;
};

export const PaizQr = (props: PaizQrProps): JSX.Element => {
    const { palette } = useTheme();

    const download = () => {
        const canvas = document.querySelector<HTMLCanvasElement>('#qr-code');
        if (canvas === null) {
            return;
        }
        var link = document.createElement('a');
        link.href = canvas.toDataURL();
        link.download = `${props.username}.png`;
        link.click();
    };

    return (
        <Stack
            alignItems="center"
            spacing={2}
        >
            <QRCodeCanvas
                id="qr-code"
                value={`https://paiz.io/${props.username}`}
                size={1000}
                fgColor={palette.text.primary}
                bgColor={palette.background.paper}
                level="H"
                imageSettings={{
                    src: '/static/images/logo-symbol.png',
                    width: 250,
                    height: 250,
                    excavate: true
                }}
                style={{
                    maxWidth: 160,
                    maxHeight: 160
                }}
            />
            <Grid
                justifyContent="center"
                container
            >
                <Grid
                    sm={9}
                    xs={12}
                    item
                >
                    <Typography
                        variant="body1"
                        color="text.secondary"
                        textAlign="center"
                    >
                        Use this QR code to drive your offline traffic online.
                    </Typography>
                </Grid>
            </Grid>
            <Button
                variant="outlined"
                color="primary"
                startIcon={
                    <SvgIcon>
                        <Download strokeWidth={1} />
                    </SvgIcon>
                }
                onClick={() => {
                    download();
                }}
            >
                Download
            </Button>
        </Stack>
    );
};