import { Accordion as MuiAccordian, AccordionDetails, AccordionProps as MuiAccordionProps, AccordionSummary, SvgIcon, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChevronDown } from 'react-feather';

const StyledAccordion = styled(MuiAccordian)({
    '&:before': {
        display: 'none'
    }
});

type AccordionProps = Omit<MuiAccordionProps, 'children'> & {
    summary: string;
    details: string;
};

export const Accordion = (props: AccordionProps): JSX.Element => {
    const {
        summary,
        details,
        ...other
    } = props;

    return (
        <div>
            <StyledAccordion
                {...other}
                elevation={0}
                disableGutters
            >
                <AccordionSummary
                    expandIcon={
                        <SvgIcon>
                            <ChevronDown />
                        </SvgIcon>
                    }
                >
                    <Typography variant="subtitle1">
                        {summary}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        variant="body1"
                        color="text.secondary"
                    >
                        {details}
                    </Typography>
                </AccordionDetails>
            </StyledAccordion>
        </div>
    );
};
