import { gql } from '@apollo/client';

export const AddonFields = gql`
    fragment AddonFields on Addon {
        id
        type
        title
        url
        thumbnail
        order
        visible
        vCard {
            firstName
            lastName
            organization
            position
            emailPrimary
            emailPrimaryType
            emailSecondary
            emailSecondaryType
            phonePrimary
            phonePrimaryType
            phoneSecondary
            phoneSecondaryType
            addressLine1
            addressLine2
            addressCity
            addressCountry
            addressState
            addressPostcode
        }
    }
`;